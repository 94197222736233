const allowedCharacters = /[^A-Za-z0-9]/g;

const kebabFileName = (fileName) => {
  const fileNameKebab = fileName
    .replaceAll(allowedCharacters, "-")
    .toLowerCase();
  return fileNameKebab;
};

export default kebabFileName;
