const { FormatError } = require("errors");

const Joi = require("joi");

function validateCommentStatus(status, explain = "status") {
  const schema = Joi.string()
    .trim()
    .valid("Approved", "Pending", "Rejected")
    .required()
    .messages({
      "string.base": `${explain} is not the correct type type`,
      "string.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(status);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new FormatError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateCommentStatus;
