import { toast } from "react-toastify";
import Loggito from "../../utils/Loggito";
import "./Unsubscribe.css";
import { unsubscribe } from "../../logic";
import { ServerError } from "errors";

function Unsubscribe() {
  const logger = new Loggito("Unsubscribe");

  const handleUnsubscribe = (event) => {
    event.preventDefault();
    unsubscribe(event.target.elements.email.value, (error) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          toast.warn(error.message);
          logger.warn(error.message);
        }
        return;
      }
      event.target.reset();
      toast.success("You successfully unsubscribed");
    });
  };

  const subscriber = window.location.search.slice(7);

  return (
    <div className="content">
      <div className="unsubscribe">
        <form action="" onSubmit={handleUnsubscribe}>
          <h2 className="h2 p--left--mobile">We are sad to see you go!</h2>
          <label htmlFor="unsubscribe" className="p--m p--left--mobile">
            Enter email of subscriber to unsubscribe:
          </label>
          <input
            type="text"
            name="email"
            value={`${subscriber ? subscriber : ""}`}
            onfocus="value=''"
            placeholder="Subscriber email"
          />
          <button className="cta cta--outline" type="submit">
            Unsubscribe
          </button>
        </form>
      </div>
    </div>
  );
}

export default Unsubscribe;
