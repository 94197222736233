import { ServerError, ClientError } from "errors";
import { validateText, validateCallback } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function getCommentsTotal(route, callback) {
  validateText(route, "route");
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const commentsArray = xhr.responseText;

      const commentsArrayParsed = JSON.parse(commentsArray);
      callback(null, commentsArrayParsed);
    }
  };

  xhr.open("GET", `${API_URL}/comments/total/${route}`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send();
}

export default getCommentsTotal;
