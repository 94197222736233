const { FormatError } = require("errors");

const Joi = require("joi");

function validateBoolean(boolean, explain = "boolean") {
  const schema = Joi.boolean()
    .required()
    .messages({
      "boolean.base": `${explain} is not the correct type`,
      "boolean.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(boolean);

  if (result.error) {
    if (result.error.details[0].type === `boolean.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new FormatError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateBoolean;
