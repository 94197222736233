import ReactGA from "react-ga4";

const useAnalyticsPageViewSender = () => {
  const gaPageViewSender = (customTitle = "title") => {
    const location = window.location.pathname;
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: customTitle,
    });
  };
  return gaPageViewSender;
};
export default useAnalyticsPageViewSender;

// Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
