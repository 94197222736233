// ========== imports ========== //
import { useEffect, useState, useRef } from "react";

// ========== Page ========== //
function QuestionTextBox({
  question,
  questionText,
  register,
  qErrors,
  onSubmit,
}) {
  // ========== Hook consts ========== //
  /* 
  // ALTERNATES VALUE TO TRIGGER TIMER
  // const { keyPressed, setKeyPressed } = useState(false);

  // const inputRef = useRef();

  const { keyReleased, setKeyReleased } = useState(false);

  useEffect(() => {
    // const input = document.querySelector(question);

    // input.addEventListener("keyup", setKeyReleased(!keyReleased));

    const delayFormSubmit = setTimeout(() => {
      onSubmit();
      return () => clearTimeout(delayFormSubmit);
    }, 3000);
  }, [keyReleased]);

  // const input = document.querySelector(question);
  debugger;

  // input.addEventListener("keyup", setKeyReleased(!keyReleased));
  window.addEventListener("keyup", setKeyReleased(!keyReleased));

  // input.addEventListener("keydown", clearTimeout(!keyPressed));

  // return () => clearTimeout(delayFormSubmit);
  // }, [searchTerm]);
  // }, []); */

  return (
    <div className="cast-form__question-container">
      <div className="cast-form__question">
        <label className="p--m cast-form__question__qtext" htmlFor={question}>
          {typeof questionText === "string" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {typeof questionText === "object"
            ? questionText.map((text, i) => {
                if (i === 0) {
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      <b>{question}</b>: {text}
                    </li>
                  );
                } else
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      {text}
                    </li>
                  );
              })
            : null}
        </label>
        <textarea
          // ref={inputRef}
          id={question}
          {...register(question, {
            /*  onChange: (e) => {
              onSubmit();
            }, */
          })}
          className="p--m"
        />
      </div>
      <div className="cast-form__error-message">
        {qErrors && <p className="input__error-message">{qErrors?.message}</p>}
      </div>
    </div>
  );
}

export default QuestionTextBox;
