import "./BlogEntry.css";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ServerError } from "errors";
import Loggito from "../../utils/Loggito";
import { Spinner } from "../Common";
import { getNewsItemClient } from "../../logic";
import parse from "html-react-parser";

import Comments from "./Comments/Comments";

import ReactPlayer from "react-player/youtube";

function BlogEntry() {
  const [newsData, setNewsData] = useState();
  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getNewsItemClient(route, (error, newsDataRetrieved) => {
        if (error) {
          if (error instanceof ServerError) {
            navigate("/news");
            toast.error(error.message);
            logger.error(error.message);
          } else {
            navigate("/news");
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setNewsData(newsDataRetrieved);
      });
    } catch (error) {
      navigate("/news");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  const logger = new Loggito("BlogEntry");

  if (newsData) {
    return (
      <div className="blog-entry">
        <div className="content">
          <h2 className="h2">{newsData.title}</h2>
          {newsData.video ? (
            <div className="player-wrapper">
              <ReactPlayer className="react-player" url={`${newsData.video}`} />
            </div>
          ) : null}

          <div className="news-item">{parse(newsData.newsHtml)}</div>
          <div className="file-links">
            {newsData.file1.fileUrl ||
            newsData.file2.fileUrl ||
            newsData.file3.fileUrl ? (
              <h3 className="h3">Files:</h3>
            ) : null}
            {newsData.file1 ? (
              <a
                href={newsData.file1.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsData.file1.fileName}
              </a>
            ) : null}
            {newsData.file2 ? (
              <a
                href={newsData.file2.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsData.file2.fileName}
              </a>
            ) : null}
            {newsData.file3 ? (
              <a
                href={newsData.file3.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsData.file3.fileName}
              </a>
            ) : null}
          </div>
        </div>
        <Comments route={newsData.route} />
      </div>
    );
  } else
    return (
      <div className="content">
        <Spinner />
      </div>
    );
}

export default BlogEntry;
