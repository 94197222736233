import Joi from "joi";

const schemaCommentStatusForm = Joi.object({
  status: Joi.string()
    .trim()
    .allow("")
    .valid("Approved", "Pending", "Rejected")
    .min(1)
    .messages({
      "string.base": "please select a valid status",
      "string.empty": "please select a status",
    }),
});

export default schemaCommentStatusForm;
