// ========== imports ========== //
import "./AdminPage.css";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Loggito from "../utils/Loggito";
import withContext from "../utils/withContext";
import {
  AddNews,
  AssessorForm,
  CASTResponsesTable,
  CommentsTable,
  CreateNews,
  EditSubscriber,
  EmailSubscribers,
  Header,
  NewsTable,
  NewsView,
  RichTextEditorTiny,
  S3Upload,
  ScoreResponse,
  Settings,
  SubCommentsTable,
  Subscribers,
  SubscribersTable,
} from "../Components/AdminComponents";

// ========== Page ========== //

function AdminPage({ handleLogoutClick, name }) {
  // ========== Hook consts ========== //

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("AdminPage");

  const routesNav = [
    {
      route: "/NewsTable",
      component: "/NewsTable",
      key: "/NewsTable",
      title: "News & Articles",
    },
    {
      route: "/SubscribersTable",
      component: "/SubscribersTable",
      key: "/SubscribersTable",
      title: "Subscribers",
    },
    {
      route: "/EmailSubscribers",
      component: "/EmailSubscribers",
      key: "/EmailSubscribers",
      title: "Email Subscribers",
    },
    {
      route: "/CASTResponsesTable",
      component: "/CASTResponsesTable",
      key: "/CASTResponsesTable",
      title: "CAST Responses",
    },
  ];
  // ========== useEffects ========== //

  // ========== Function to navigate between pages ========== //
  const handleSettingsClick = () => {
    navigate("settings");

    logger.debug("navigate to settings");
  };

  const handleHomeClick = () => {
    navigate("/admin");

    logger.debug("navigate to home");
  };

  // make sure location.pathname is being passed here
  const handleNavigateTo = (location) => {
    navigate(`/`);
    navigate(location);
  };

  // ========== jsx ========== //

  return (
    <div className="admin-page">
      <Header
        name={name}
        onLogoutClick={handleLogoutClick}
        onSettingsClick={handleSettingsClick}
        onHomeClick={handleHomeClick}
      />
      <div className="admin-page__content-nav">
        <div>
          <ul className="list admin__nav">
            {routesNav.map((element) => {
              return (
                <li className="" key={element.key}>
                  <button
                    className=""
                    // I put toLowerCase but I don't think it's necessary
                    onClick={() =>
                      navigate(`${element.route.slice(1).toLowerCase()}`)
                    }
                  >
                    {element.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="admin__content">
          {location.pathname === "/admin" ? (
            <div className="welcome-message">
              <h2 className="h2">Welcome</h2>
              <p className="p--m p--centered">
                Login successful, please use the menu links to add/edit/delete
                records in the database and to email subscribers.
              </p>
            </div>
          ) : null}
          <Routes>
            <Route path="/news/edit/:route" element={<AddNews />} />
            <Route path="/news/view/:route" element={<NewsView />} />
            <Route path="/createnews" element={<CreateNews />} />
            <Route path="/emailsubscribers" element={<EmailSubscribers />} />
            <Route path="/newstable" element={<NewsTable />} />
            <Route path="/news/comments/:route" element={<CommentsTable />} />
            <Route
              path="/news/comments/:route/:mainCommentId"
              element={<SubCommentsTable />}
            />
            <Route
              path="/richtexteditortiny"
              element={<RichTextEditorTiny />}
            />
            <Route path="/s3upload" element={<S3Upload />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/subscribers" element={<Subscribers />} />
            <Route
              path="/subscribers/edit/:route"
              element={<EditSubscriber />}
            />
            <Route path="/subscriberstable" element={<SubscribersTable />} />
            <Route
              path="/castresponsestable"
              element={<CASTResponsesTable />}
            />
            <Route
              path="/responses/:environment/:route"
              element={<AssessorForm />}
            />
            <Route
              path="/formresponses/scoring/:email"
              element={<ScoreResponse />}
            />
          </Routes>
        </div>
      </div>
      <footer className=""></footer>
    </div>
  );
}

export default withContext(AdminPage);
