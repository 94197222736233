import { Cta } from "../Common";

import "./WhyCharacter.css";

function WhyCharacter() {
  return (
    <section className="section why-character">
      <div className="title-block">
        <h2 className="h2">Why Character</h2>
      </div>
      <div className="character-quotes-block">
        <p className="p--m p--centered">
          <span className="character-quote">
            “The 1st step in developing a high-performance culture – select on
            character.”
          </span>
          <br />
          <span className="p--xs p--bold">
            (Owen Eastwood, Leadership Consultant)
          </span>
        </p>
        <p className="p--m p--centered">
          <span className="character-quote">
            &nbsp;
            <br />
            “Character triumphs over talent.”
          </span>
          <br />
          <span className="p--xs p--bold">(James Kerr)</span>
        </p>
        <p className="p--m p--centered">
          <span className="character-quote">
            &nbsp;
            <br />
            “Hire Character: Train Skill”
          </span>
          <br />
          <span className="p--xs p--bold">
            (Peter Werner Schutz, President and CEO of Porsche, 1981-1987)
          </span>
        </p>
      </div>
      <div className="paragraph--closing">
        <p className="p--m">
          CAST assesses Character and Values. These qualities are extremely
          difficult to detect in the typical competency-based interview.
          Character rather than technical ability is intricately connected to
          resilience. “Human brains are wired for acquiring skills. …skills are
          easy to assess, measure, and test.” (Diviney, 2021)
        </p>
        <p className="p--m p--centered">
          <span className="character-quote">
            "Resilience is the most important soft skill to have and will
            continue to be crucial."
          </span>
          <br />
          <span className="p--xs p--bold">
            (Sarah Dowzell (2020), COO and co-founder of Natural HR)
          </span>
        </p>
        <p className="p--m">
          Resilience determines not only a person’s ability to survive but also
          to thrive in challenging situations. Effective resilience combines
          sensitivity and emotional intelligence with coping abilities.
          Character and resilience shape a person’s attitude towards their life,
          work, and development.
        </p>
        <p className="p--m">
          Resilience is not fixed and will be influenced by factors such as the
          specific nature of the event and context, and the support available.
          The insights provided by CAST enable focused support to build
          resilience.
        </p>
      </div>
      <Cta text="Ask a question" type="cta--accent" to="/contact" />
      <div class="wrapper grid grid--1x2">
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            Our organization has commissioned over 15 CAST assessments during
            the past two years. We have done this to focus on the development of
            managers and senior staff. We have also used it for selection
            purposes. The assessments in my experience are an accurate indicator
            of a person's current mindset and abilities.
            <span className="username">Director, Ireland</span>
          </p>
        </div>
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            I highly recommend CAST as a tool for staff appointment, retention,
            and professional development. This assessment not only identifies
            the right people for an organisation but continues as a management
            tool.
            <span className="username">Company Founder, India</span>
          </p>
        </div>
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            I'd really recommend CAST for recruiting to board or high-level
            roles, and when promoting individuals in-house. It’s an exceptional
            analysis.
            <span className="username">
              Development Lead, Marketing Director, England.
            </span>
          </p>
        </div>
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            The assessment process, written report, and feedback were
            exceptional.
            <span className="username">Assistant Commissioner, Ireland</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhyCharacter;
