import { ServerError, ClientError } from "errors";
import { validateCallback, validateToken } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateCastFormResponse(token, route, values, callback) {
  // TODO: validate values
  validateToken(token);
  // import module to validate all values
  validateCallback(callback);
  /*   const {
    fullName,
    gender,
    email,
    organisation,
    writtenResponse,
    stressLevel,
    subscribe,
  } = values; */

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("PATCH", `${API_URL}/forms/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      values,
    })
  );
}

export default updateCastFormResponse;
