const Joi = require("joi");

function validatePassword(password, explain = "password") {
  const schema = Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base": `${explain} must contain at least one lower case letter, an upper case letter and a number`,
      "string.base": `${explain} is not the correct type`,
      "string.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(password);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else if (result.error.details[0].type === `string.empty`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else if (result.error.details[0].type === `string.pattern.base`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else throw new Error(`error with ${explain}: ${explain} is not valid`);
  }

  return `${explain} validated successfully`;
}

export default validatePassword;
