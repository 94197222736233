import "./Footer.css";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <p className="p--sm p--centered">
        © CAST, All rights reserved<span> | </span>
        <Link className="footer__link" to={"/privacy-policy"}>
          Privacy Policy
        </Link>
        <span> | </span>
        <Link className="footer__link" to={"/sitemap"}>
          Sitemap
        </Link>
      </p>
      <p className="p--sm p--centered">
        By using this site, you agree we can set and use cookies.{" "}
        <Link className="footer__link" to={"/cookies-policy"}>
          View cookies policy
        </Link>
      </p>
    </div>
  );
}

export default Footer;
