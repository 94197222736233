import { ServerError, ClientError } from "errors";
import {
  validateToken,
  validateEmail,
  validateCallback,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Deletes a subscriber from the database
 *
 * @param {string} token The user session token
 * @param {string} email The email of the subscriber to delete
 * @param {function} callback The function expression that provides a result
 *
 * @throws {TypeError} On invalid inputs
 */

function deleteSubscriber(token, email, callback) {
  validateToken(token);
  validateEmail(email);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;
    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) callback(null);
  };

  xhr.open("DELETE", `${API_URL}/subscribers/${email}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send();
}

export default deleteSubscriber;
