// ========== Imports ========== //

import "./Spinner.css";

import { useEffect } from "react";
import Loggito from "../../utils/Loggito";

// ========== Component ========== //

function Spinner() {
  // ========== consts ========== //

  const logger = new Loggito("Spinner");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== jsx ========== //

  return (
    // <div className="spinner-page">
    <div className="spinner-container">
      <p className="spinner__loading">Loading...</p>
      <div className="spinner-container--dots">
        <div className="dot-container flex-">
          <div className="dot dot1"></div>
        </div>
        <div className="dot-container">
          <div className="dot dot2"></div>
        </div>
        <div className="dot-container">
          <div className="dot dot3"></div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
