import Joi from "joi";

const schemaSettingsPasswordForm = Joi.object({
  oldPassword: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please enter your password",
    }),
  newPassword: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please enter a new password",
    }),
  confirmNewPassword: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please retype the new password",
    }),
});

export default schemaSettingsPasswordForm;
