// ========== imports ========== //
import "./AddMainComment.css";

import Loggito from "../../../utils/Loggito";

import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { submitComment } from "../../../logic";
import { schemaAddCommentForm } from "../../../validators";

// ========== Page ========== //

function AddMainComment({ route, type, mainCommentId }) {
  // ========== Hook consts ========== //
  const [commentIsOpen, setCommentIsOpen] = useState(false);

  const commentForm = useRef();

  const navigate = useNavigate();
  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaAddCommentForm) });

  const logger = new Loggito("AddMainComment");
  // ========== useEffects ========== /

  const handleSubmitComment = async (data) => {
    const commentData = {};

    commentData.comment = data.comment;
    commentData.name = data.name;
    commentData.email = data.email;
    commentData.post = route;
    commentData.type = type;

    if (type === "main") {
      try {
        submitComment(commentData, route, null, function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          reset();
          setCommentIsOpen(!commentIsOpen);
          toast.success("Success! Thank you, your comment is under review");
          handleOpenComment();
        });
      } catch (error) {
        logger.warn(error.message);
        toast.warn(error.message);
      }
    } else if (type === "sub") {
      try {
        submitComment(commentData, route, mainCommentId, function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          reset();
          setCommentIsOpen(!commentIsOpen);
          toast.success("Success! Thank you, your comment is under review");
        });
      } catch (error) {
        logger.warn(error.message);
        toast.warn(error.message);
      }
    }
  };

  const handleFormSubmit = (data) => {
    handleSubmitComment(data);
  };

  const handleOpenComment = () => {
    setCommentIsOpen(!commentIsOpen);
  };

  // ========== jsx ========== //
  return (
    <div className="add-main-comment-section">
      {type === "main" && commentIsOpen ? (
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="add-main-comment-form"
          noValidate={true}
          ref={commentForm}
        >
          <button
            className="cta cta--outline"
            type="button"
            onClick={handleOpenComment}
          >
            Close
          </button>
          <label htmlFor="comment">Comment:</label>
          <textarea {...register("comment", {})} />
          {errors.comment && (
            <p className="input__error-message">{errors.comment?.message}</p>
          )}

          <div>
            <label htmlFor="name">First name:</label>
            <input
              type="text"
              placeholder=""
              name="name"
              {...register("name", {})}
            />
            {errors.name && (
              <p className="input__error-message">{errors.name?.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              placeholder=""
              name="email"
              {...register("email", {})}
            />
            {errors.email && (
              <p className="input__error-message">{errors.email?.message}</p>
            )}
          </div>

          <button className="cta cta--accent" type="submit">
            Submit
          </button>
        </form>
      ) : null}
      {type === "main" && !commentIsOpen ? (
        <button
          className="cta cta--secondary"
          type="button"
          onClick={handleOpenComment}
        >
          Write comment
        </button>
      ) : null}

      {type === "sub" ? (
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="add-main-comment-form"
          noValidate={true}
          ref={commentForm}
        >
          <label htmlFor="comment">Comment:</label>
          <textarea {...register("comment", {})} />
          {errors.comment && (
            <p className="input__error-message">{errors.comment?.message}</p>
          )}

          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              placeholder=""
              name="name"
              {...register("name", {})}
            />
            {errors.name && (
              <p className="input__error-message">{errors.name?.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              placeholder=""
              name="email"
              {...register("email", {})}
            />
            {errors.email && (
              <p className="input__error-message">{errors.email?.message}</p>
            )}
          </div>

          <button type="submit" className="cta cta--accent">
            Submit
          </button>
        </form>
      ) : null}
    </div>
  );
}

export default AddMainComment;
