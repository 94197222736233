// ========== Imports ========== //

import "./FormResponseTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import { getFormResponses } from "../../../logic";
import { DeleteSubscriber } from "../";

import { Pagination } from "../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("SubscribersTable");

// Receives the eventId and return the table

function FormResponseTable() {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 20;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return responsesData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(response, index) {
    // ========== JSX ========== //

    return (
      <tr key={response.email}>
        <td>{index + 1}</td>
        <td>{response.fullName}</td>
        <td>{response.gender}</td>
        <td>{response.email}</td>
        <td>{response.dateCreated.slice(0, 10)}</td>
        <td className="buttons-td">
          {/* <QRGenerator subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} />
          <EditButton subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} />
        */}
          <Link
            to={`/admin/formresponses/scoring/${response.email}`}
            className="link"
          >
             Scoring
          </Link>{" "}
          /{/* <DeleteSubscriber subscriberEmail={response.email} /> */}
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      // const subscribersArray =
      getFormResponses(sessionStorage.token, (error, responsesArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        // return subscribersArray.map(rowTable);
        setResponsesData(responsesArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
    /*  try {
      async function getData() {
        const result = await printResponses();
        setSubscribersData(result);
      }
      getData();
    } catch (error) {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          toast.warn(error.message);
          logger.warn(error.message);
        }
        return;
      }
    } */
  }, []);

  // ========== JSX ========== //

  if (responsesData !== "") {
    return (
      <div className="subscribers-table">
        <h1>Manage Subscribers</h1>
        {/* <AddEvent /> */}
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Key</th>
              <th>Full Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          {/* <tbody>{subscribersData ? subscribersData : null}</tbody> */}
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={responsesData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default FormResponseTable;

// TODO: refactor this helper into a component

async function printResponses() {
  function rowTable(response, index) {
    // ========== JSX ========== //

    return (
      <tr key={response.email}>
        <td>{index}</td>
        <td>{response.firstName}</td>
        <td className="backoffice-table__description">{response.surname}</td>
        <td>{response.email}</td>
        <td>{response.dateSubscribed}</td>
        <td className="buttons-td">
          {/* <QRGenerator subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} />
          <EditButton subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} />
          <Deletesubscriber subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} text={"X"} /> */}
        </td>
      </tr>
    );
  }

  try {
    // const subscribersArray =
    getFormResponses(sessionStorage.token, (error, subscribersArray) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          toast.warn(error.message);
          logger.warn(error.message);
          if (error.message === "session timed out, please log in again") {
            delete sessionStorage.token;
            window.location.reload(false);
          }
        }
        return;
      }
      return subscribersArray.map(rowTable);
    });
  } catch (error) {
    toast.warn(error.message);
    logger.warn(error.message);
  }
}
