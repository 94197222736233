import { ServerError, ClientError } from "errors";
import { validateEmail, validateCallback } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function sendResetPasswordCode(email, callback) {
  validateEmail(email);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) callback(null);
  };

  xhr.open("PATCH", `${API_URL}/users/sendresetpasswordcode`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(`{ "email": "${email}"}`);
}

export default sendResetPasswordCode;
