import {
  Home,
  Purpose,
  CastInsights,
  ServiceOptionsAndPrices,
  TheAssessment,
} from "../Screens";
import {
  CookiesPolicy,
  PrivacyPolicy,
  SiteMap,
  Unsubscribe,
} from "../Components/TertiaryPages";
import {
  Footer,
  HeaderMobileTablet,
  HeaderDesktop,
} from "../Components/Navigation";
import { Blog, BlogEntry } from "../Components/Blog";
import Contact from "../Components/Contact/Contact";
import withContext from "../utils/withContext";
import Context from "../utils/Context";

import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./HomePage.css";

function HomePage() {
  const [shareTitle, setShareTitle] = useState(
    "CAST Character Assessment & Selection Tool"
  );
  const [shareText, setShareText] = useState(
    `A link to ${shareTitle} at wwww.castassessment.com`
  );

  const handleUpdateShareTitle = (title) => {
    setShareTitle(title);
  };

  return (
    <Context.Provider
      value={{
        handleUpdateShareTitle,
      }}
    >
      <div>
        <div className="home-page">
          <HeaderMobileTablet />
          <HeaderDesktop shareTitle={shareTitle} shareText={shareText} />
          <main className="home-page__main">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/purpose" element={<Purpose />} />
              <Route path="/insights" element={<CastInsights />} />
              <Route path="/assessment" element={<TheAssessment />} />
              <Route path="/services" element={<ServiceOptionsAndPrices />} />
              <Route path="/news" element={<Blog />} />
              <Route path="/news/:route" element={<BlogEntry />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/sitemap" element={<SiteMap />} />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />

              <Route path="/" element={<Home />} />
            </Routes>
          </main>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    </Context.Provider>
  );
}

export default withContext(HomePage);
