import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validateToken,
  validateText,
  validateEmail,
  validatePassword,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateUserDetails(token, formValues, callback) {
  const {
    formId,
    updatedName,
    password,
    newEmail,
    oldPassword,
    newPassword,
    confirmNewPassword,
  } = formValues;

  //====== validation ======//
  validateToken(token);
  validateText(formId);
  validateCallback(callback);

  if (formId === "nameForm") {
    validatePassword(password);
    validateText(updatedName);
  }

  if (formId === "passwordForm") {
    if (newPassword !== confirmNewPassword)
      throw new FormatError("New and confirmed passwords do not match");
    validatePassword(newPassword);
    validatePassword(oldPassword);
    validatePassword(confirmNewPassword);
  }

  if (formId === "emailForm") {
    validatePassword(password);
    validateEmail(newEmail);
  }

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      /* xhr.onerror = function () {
        console.log("API CALL ERROR");
      }; */
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/users/details`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  if (formId === "nameForm")
    xhr.send(
      JSON.stringify({
        formId: formId,
        updatedName: updatedName,
        password: password,
      })
    );
  else if (formId === "passwordForm")
    xhr.send(
      JSON.stringify({
        formId: formId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      })
    );
  else if (formId === "emailForm")
    xhr.send(
      JSON.stringify({
        formId: formId,
        newEmail: newEmail,
        password: password,
      })
    );
}

export default updateUserDetails;
