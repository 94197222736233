// ========== imports ========== //
import { useState } from "react";

import { HiChevronRight } from "react-icons/hi";
// ========== Page ========== //
function QuestionAssessmentContainer({
  question,
  watch,
  scoreType,
  scoreExplanation,
  register,
  category,
  onSubmit,
}) {
  // ========== Hook consts ========== //
  const [isActiveComment, setIsActiveComment] = useState(false);

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */

    <div
      className={`cast-form__question-assessment-container ${
        category === "supportive"
          ? "cast-form__question-assessment-container--supportive"
          : ""
      } ${
        category === "unsupportive"
          ? "cast-form__question-assessment-container--unsupportive"
          : ""
      } ${
        category === "demanding"
          ? "cast-form__question-assessment-container--demanding"
          : ""
      } ${
        category === "undemanding"
          ? "cast-form__question-assessment-container--undemanding"
          : ""
      } ${
        category === undefined
          ? "cast-form__question-assessment-container--standard"
          : ""
      }`}
    >
      {scoreType !== "Assessor scored" ? (
        <div className="cast-form__question-assessment">
          <p className="p--m">{scoreExplanation ? scoreType : ""}</p>
          <p className="p--m">
            <b>{question}</b>: {scoreType} -{" "}
            {scoreType === "Automatically scored" ? (
              <span className="question-score">{watch(question)}</span>
            ) : null}
          </p>
          {/* <div
            className={`collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <textarea
                id={question + "_comment"}
                {...register(question + "_comment", {})}
                s
                className="p--m"
              />
            </div>
          </div> */}
          <div
            className={`cast-form__comments collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <textarea
                id={question + "_comment"}
                {...register(question + "_comment", {})}
                className="p--m"
              />
            </div>
          </div>
        </div>
      ) : null}
      {scoreType === "Assessor scored" ? (
        <div className="cast-form__question cast-form__question--radio">
          <div className="radio-options--assessor">
            <div className="cast-form__question-assessment">
              <p className="p--m">{scoreExplanation ? scoreType : ""}</p>
              <p className="p--m">
                <b>{question}</b>: {scoreType} -{" "}
              </p>
            </div>
            <div>
              <label htmlFor={question + "_0"} className="p--sm">
                0
              </label>
              <input
                type="radio"
                id={question + "_0"}
                name={question}
                value={"0"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_1"} className="p--sm">
                1
              </label>
              <input
                type="radio"
                id={question + "_1"}
                name={question}
                value={"1"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_2"} className="p--sm">
                2
              </label>
              <input
                type="radio"
                id={question + "_2"}
                name={question}
                value={"2"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_3"} className="p--sm">
                3
              </label>
              <input
                type="radio"
                id={question + "_3"}
                name={question}
                value={"3"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
          </div>
          <div
            className={`cast-form__comments collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <textarea
                id={question + "_comment"}
                {...register(question + "_comment", {})}
                className="p--m"
              />
            </div>
          </div>
        </div>
      ) : null}
      {/* ERROR BLOCK - WITHIN Q CONTAINER */}
      {/* TODO: add errors to assessor score */}
      {/* <div className="cast-form__error-message">
          {qErrors && (
            <p className="input__error-message">{qErrors?.message}</p>
          )}
        </div> */}
    </div>
  );
}

export default QuestionAssessmentContainer;
