// ========== Imports ========== //

import "./CASTResponsesTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import CreateForm from "./CreateForm";
import DeleteForm from "./DeleteForm";
import { getFormResponses } from "../../../logic";

import { Pagination } from "../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

function CASTResultsTable() {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 12;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return responsesData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(response, index) {
    // ========== JSX ========== //

    return (
      <tr key={response.route}>
        <td>{response.route}</td>
        {/* <td>{response.fullName}</td> */}
        <td>{response.fullName}</td>
        <td>{response.candidateEmail}</td>
        <td>{response.dateCreated.slice(0, 10)}</td>
        {/* <td>{response.status}</td> */}
        <td>Pending Response</td>
        <td>
          <Link to={`/admin/responses/${response.route}`} className="link">
            Manage Response
          </Link>
        </td>
        <td className="buttons-td">
          {
            <>
              <Link
                to={`/admin/responses/view/${response.route}`}
                className="link"
              >
                View
              </Link>{" "}
              /
              <Link
                to={`/admin/responses/edit/${response.route}`}
                className="link"
              >
                 Edit
              </Link>{" "}
              /
              <DeleteForm form={response} />
            </>
          }
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getFormResponses(sessionStorage.token, (error, responsesArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        setResponsesData(responsesArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (responsesData !== "") {
    return (
      <div className="responses-table">
        <h1>Manage Responses</h1>
        <CreateForm />

        <table className="table table-hover">
          <thead>
            <tr>
              <th>Key</th>
              <th>Full name</th>
              <th>Email</th>
              <th>Date created</th>
              <th>Status</th>
              <th>Reporting</th>
              <th>Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={responsesData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return (
      <div className="responses-table">
        <h1>Manage Responses</h1>
        <CreateForm />
        <Spinner />;
      </div>
    );
  }
}

export default CASTResultsTable;
