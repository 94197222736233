// ========== Imports ========== //

import "./Menu.css";

import { useEffect } from "react";
import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";

// ========== Component ========== //

function Menu({ menuView, onLogoutClick, onSettingsClick, onHomeClick }) {
  // ========== consts ========== //

  const logger = new Loggito("Menu");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions: change pages within home ========== //

  const handleLogoutClick = () => onLogoutClick();

  const handleSettingsClick = () => {
    onSettingsClick();
  };

  const handleHomeClick = () => {
    onHomeClick();
  };

  // ========== Values to map the menu ========== //

  const elementsMenu =
    menuView === "home"
      ? [
          { text: "Settings", action: handleSettingsClick, key: 1 },
          { text: "Logout", action: handleLogoutClick, key: 3 },
        ]
      : [
          { text: "Home", action: handleHomeClick, key: 2 },
          { text: "Logout", action: handleLogoutClick, key: 3 },
        ];

  // ========== jsx ========== //

  return (
    <>
      <div className="menu-panel">
        <ul className="dropdown-menu menu-panel__list">
          {elementsMenu.map((element) => {
            return (
              <li
                className="menu-panel__list-item dropdown-item settings-button"
                key={element.key}
                onClick={element.action}
              >
                <button className="button dropdown__link">
                  {element.text}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default withContext(Menu);
