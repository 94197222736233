import { ServerError, ClientError } from "errors";
import {
  validateText,
  validateCallback,
  validateToken,
  validateEmail,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function submitComment(commentData, route, mainCommentId, callback) {
  validateText(route, "route");
  validateCallback(callback);

  const { name, email, comment, type } = commentData;
  validateText(name, "name");
  validateText(type, "type");
  validateText(comment, "comment");
  validateEmail(email, "email");

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;
    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("POST", `${API_URL}/comments/${route}/${mainCommentId}`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(JSON.stringify({ name, email, comment, type }));
}

export default submitComment;
