// ========== imports ========== //
import "./CASTForm.css";

import React, { useRef, useState, useEffect } from "react";
import {
  Section1,
  Section2,
  SectionA,
  SectionB,
  SectionC,
  SectionD,
  SectionE,
  SectionF,
  SectionG,
  SectionH,
  FormAnalysisTable,
} from "./FormSections";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../utils/useAnalyticsPageViewSenderGA4";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Loggito from "../../../utils/Loggito";
import withContext from "../../../utils/withContext";

import { updateCastFormResponse } from "../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function AssessorForm() {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const params = useParams();

  const environment = params.environment;

  console.log(environment);

  // ========== other consts ========== //

  const logger = new Loggito("CAST Form Dev");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div>
      <div className="section cast-form-container">
        <Section1 environment={environment} />

        <SectionA environment={environment} />

        <SectionB environment={environment} />
        <SectionC environment={environment} />
        <SectionD environment={environment} />
        <SectionE environment={environment} />
        <SectionF environment={environment} />

        <FormAnalysisTable environment={environment} />
      </div>
    </div>
  );
}

export default withContext(AssessorForm);
