// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSectionD } from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
  QuestionMcqListHorizontal,
  QuestionTextBox,
  QuestionShortText,
  QuestionMcqYesNo,
} from "./FormComponents";

// ========== Page ========== //

function SectionD({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerD,
    handleSubmit: handleSubmitD,
    reset,
    formState: { errors },
    getValues: getValuesD,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionD),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "D" && value) {
            setValue(key, value.response, {});
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
          } */
        if (key[0] === "D") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => form.current.requestSubmit(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesD();

    console.log(values);

    const groupedValuesObject = {
      D1: {
        response: values.D1,
        score: values.D1_score,
        comment: values.D1_comment,
      },
      D2: {
        response: values.D2,
        score: values.D2_score,
        comment: values.D2_comment,
      },
      D3: {
        response: values.D3,
        score: values.D3_score,
        comment: values.D3_comment,
      },
      D4: {
        response: values.D4,
        score: values.D4_score,
        comment: values.D4_comment,
      },
      D5: {
        response: values.D5,
        score: values.D5_score,
        comment: values.D5_comment,
      },
      D6: {
        response: values.D6,
        score: values.D6_score,
        comment: values.D6_comment,
      },
      D7a: {
        response: values.D7a,
        score: values.D7a_score,
        comment: values.D7a_comment,
      },
      D7b: {
        response: values.D7b,
        score: values.D7b_score,
        comment: values.D7b_comment,
      },
      D8: {
        response: values.D8,
        score: values.D8_score,
        comment: values.D8_comment,
      },
      D9: {
        response: values.D9,
        score: values.D9_score,
        comment: values.D9_comment,
      },
      D10a: {
        response: values.D10a,
        score: values.D10a_score,
        comment: values.D10a_comment,
      },
      D10b: {
        response: values.D10b,
        score: values.D10b_score,
        comment: values.D10b_comment,
      },
    };

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        groupedValuesObject,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section D");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={handleSubmitD((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section D</h2>
        <p className="p--m">
          This section has two types of questions; some where you will be asked
          to write down your answers and some will be statements that you score
          from 0-3 . Score these statements from 0-3 based on how much you agree
          or disagree with them.
          <br />
          <br />
          0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
          agree
          <br /> <br />
          On the first 4 questions think of the general trend rather than the
          exception to the rule.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D1"}
        questionText={
          "Taking responsibility tends to be a trait of successful people and blaming tends to be a trait of unsuccessful people."
        }
        register={registerD}
        qErrors={errors.D1}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D2"}
        questionText={
          "Where you end up in life is dictated by the choices you make."
        }
        register={registerD}
        qErrors={errors.D2}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D3"}
        questionText={
          "Success is not the key to happiness, happiness is the key to success."
        }
        register={registerD}
        qErrors={errors.D3}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D4"}
        questionText={
          "I can fail many times, but not become a failure until I start to blame others."
        }
        register={registerD}
        qErrors={errors.D4}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionMcqListHorizontal
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D5"}
        questionText={
          "Please think of a dimension with pessimism at one end and optimism at the other end, with realist in the middle, where do you place yourself?"
        }
        register={registerD}
        qErrors={errors.D5}
        labels={[
          "Pessimist",
          "Pessiminst-Realist",
          "Realist",
          "Realist-Optimist",
          "Optimist",
        ]}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionTextBox
        question={"D6"}
        questionText={
          "Please say if you are currently learning and developing yourself and if so how?"
        }
        register={registerD}
        qErrors={errors.D6}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D6"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionMcqYesNo
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D7a"}
        questionText={
          "Do you have a habit you would like to change and if so, do you have a plan for how to change it?"
        }
        register={registerD}
        qErrors={errors.D7a}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D7a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionTextBox
        question={"D7b"}
        questionText={
          "Please say if you are currently learning and developing yourself and if so how?"
        }
        register={registerD}
        qErrors={errors.D7b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D7b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionShortText
        question={"D8"}
        questionText={"What things do you feel gratitude for, and why?"}
        register={registerD}
        qErrors={errors.D8}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D8"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionShortText
        question={"D9"}
        questionText={"What does it mean to you to have 'courage' at work?"}
        register={registerD}
        qErrors={errors.D9}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D9"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionShortText
        question={"D10a"}
        questionText={
          "Imagine this scenario: You can’t get all the work done that is expected of you. What would be the typical reason if you were not able to complete your work?"
        }
        register={registerD}
        qErrors={errors.D10a}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D10a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}
      <QuestionShortText
        question={"D10b"}
        questionText={
          "In the scenario above, how would you (typically) deal with not completing the work that was expected of you?"
        }
        register={registerD}
        qErrors={errors.D10b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D10b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionD);
