import ReactGA from "react-ga4";

const useAnalyticsEventsTracker = () => {
  const gaEventTracker = (
    customTitle = "title",
    action = "user action"
  ) => {
    const location = window.location.pathname;
    ReactGA.event({
      category: customTitle,
      action: action,
      label: customTitle, // optional
      // value: 99, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      // transport: "xhr", // optional, beacon/xhr/image
    });
  };
  return gaEventTracker;
};
export default useAnalyticsEventsTracker;

// Send a custom event
/* ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
}); */
