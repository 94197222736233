const testimonialData = [
  {
    id: 1,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_juliet_allan.jpeg",
    name: "Juliet Allan",
    title: "Development Lead, Marketing Director, England",
    quote:
      "I'd really recommend CAST for recruiting to board or high-level roles, and when promoting individuals in-house. It’s an exceptional analysis.",
  },
  {
    id: 2,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_edel_walsh.jpeg",
    name: "Edel Walsh",
    title: "Assistant Commissioner, N. Ireland",
    quote:
      "The relational approach utilized to complete the CAST I felt set it superior to other tools as within that relational exchange Patrick was able to ascertain more about my personal qualities but more importantly my values.",
  },
  {
    id: 3,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_sean.jpg",
    name: "Sean Dunne",
    title: "Operations Manager, UK",
    quote:
      "From Owner/Director to entry-level, almost a fifth of our workforce was assessed to help us better understand their needs and how they fit into the company’s overall mission. The results could not be more positive. Through a simple question and answer process, completed over a 75-minute session, we gained insight into employees’ strengths and challenges, appropriate methods of support, and current and future role suitability.",
  },
  {
    id: 4,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_mark_mcsherry.jpg",
    name: "Mark McSherry",
    title: "Director, Ireland",
    quote:
      "Our organization has commissioned over 15 CAST assessments during the past two years. We have done this to focus on the development of managers and senior staff. We have also used it for selection purposes. The assessments in my experience are an accurate indicator of a person's current mindset and abilities. They also highlight the areas of development to focus on what will enable an individual not only to develop professionally but also personally.",
  },
  {
    id: 5,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_andrew_davies.jpg",
    name: "Andrew Davies",
    title: "Senior Fieldwork Manager, UK",
    quote:
      "I highly recommend the assessment for anyone seeking to understand their strengths and areas for development and to help them set clear development goals for the future.",
  },
  {
    id: 6,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_juliet_allan.jpeg",
    name: "Juliet Allan",
    title: "Development Lead, Marketing Director, England",
    quote:
      "The CAST Assessment tool is brilliantly insightful and delivered in a careful, thoughtful, and affirming way that encourages self-care and self-challenge. It's different from other assessment tools in that it examines aspects of 'character' (as opposed to personality), to understand individuals' potential for leadership, resilience, and undertaking high-level responsibility. ",
  },
  {
    id: 7,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_wayne_okell.jpeg",
    name: "Wayne Okell MBE",
    title: "Director of Talent & Performance, UK",
    quote:
      "CAST is a very, very smart tool indeed. I was thoroughly impressed with the process too, it's very personable and sincerely accurate and if you are serious about your personal/professional development and the development of others, then look no further.",
  },
  {
    id: 8,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_edel_walsh.jpeg",
    name: "Edel Walsh",
    title: "Assistant Commissioner, N. Ireland",
    quote:
      "The assessment process, written report, and feedback were exceptional.",
  },
  {
    id: 9,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_tanushree.jpg",
    name: "Tanushree Handoo",
    title: "Company Founder, India",
    quote:
      "I found the CAST assessment extremely helpful in providing me with an understanding of my strengths and areas of future work. Having a greater awareness of areas requiring development has given me an understanding of where my focus should be.",
  },
  {
    id: 10,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_darlene_lyons.jpeg",
    name: "Darlene Lyons",
    title: "Team Leader, N. Ireland",
    quote:
      "I recommend the assessment for any manager who wants to focus on selecting the appropriate staff for positions and identifying ways of developing resilience.",
  },
  {
    id: 11,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_mark_mcsherry.jpg",
    name: "Mark McSherry",
    title: "Director, Ireland",
    quote:
      "There are several examples I can recall where the CAST assessment identified strengths and areas of development in an individual early on in their employment with our organisation that became uncannily and highly accurate once the individuals became embedded in their role.",
  },
  {
    id: 12,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_sean.jpg",
    name: "Sean Dunne",
    title: "Operations Manager, UK",
    quote:
      "From Owner/Director to entry-level, almost a fifth of our workforce was assessed to help us better understand their needs and how they fit into the company’s overall mission. The results could not be more positive. Through a simple question and answer process, completed over a 75-minute session, we gained insight into employees’ strengths and challenges, appropriate methods of support, and current and future role suitability.",
  },
  {
    id: 13,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_luci.jpg",
    name: "Luci Klendo",
    title: "Therapeutic Specialist, Family Services, Australia",
    quote:
      "The CAST assessment is a highly practical tool that complements standard recruitment procedures, offering a great benefit to individuals and organisations alike. The considered approach to staff recruitment and development encompasses a true understanding of the qualities most relevant to professionals working with people across the lifespan.",
  },
  {
    id: 14,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_tanushree.jpg",
    name: "Tanushree Handoo",
    title: "Company Founder, India",
    quote:
      "I highly recommend CAST as a tool for staff appointment, retention, and professional development. This assessment not only identifies the right people for an organisation but continues as a management tool. Thanks, I believe I have made improvements in my performance following my assessment with you.",
  },
  {
    id: 15,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_colby.jpeg",
    name: "Colby Pearce",
    title: "Clinical Psychologist, Australia",
    quote:
      "I found the assessment process straightforward, engaging, and thought-provoking. The assessment outcomes were representative of my approach to work and family life. The assessment supported valuable insights and a sense of empowerment.",
  },
  {
    id: 16,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_giulia.jpg",
    name: "Giulia Prencipe",
    title: "University Lecturer, Middlesex, UK",
    quote:
      "I valued the one-to-one approach used for the assessment, and the personal brief given of the results thereof, opening a conversation for further developments. I found the whole assessment process educational and actionable.",
  },
  {
    id: 17,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_wayne_okell.jpeg",
    name: "Wayne Okell MBE",
    title: "Director of Talent & Performance, UK",
    quote:
      "The assessment is extremely thorough. The results are very revealing and provide clarity and awareness of what and where to improve. CAST has had a genuine impact and focus on the adaptations needed and resilience required to adopt them. As a direct consequence, I would highly recommend this tool.",
  },
  {
    id: 18,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_andrew_davies.jpg",
    name: "Andrew Davies",
    title: "Senior Fieldwork Manager, UK",
    quote:
      "I thoroughly enjoyed the CAST assessment. Patrick was relaxed, friendly, and informative and he put me at ease throughout the assessment process. He took the time to explain the different parts of the assessment and the research supporting each part.",
  },
  {
    id: 19,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_rui.jpg",
    name: "Rui Lopes",
    title: "Director, Portugal",
    quote:
      "Through the assessment interview, I was helped to identify the main characteristics associated with my leadership abilities and skills. The results, and most of all, the way they were presented to me, gave me important information to set goals for professional development.",
  },
  {
    id: 20,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_luci.jpg",
    name: "Luci Klendo",
    title: "Therapeutic Specialist, Family Services, Australia",
    quote:
      "Verbal and written feedback gave me valuable insight into my strengths and areas for development that enabled me to position my career goals effectively.",
  },
  {
    id: 21,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_sean.jpg",
    name: "Sean Dunne",
    title: "Operations Manager, UK",
    quote:
      "For any company wanting to gain a greater understanding of their employees and how they and you can best support the task, I highly recommend this assessment and we will certainly be using the assessment in our future development.",
  },
  {
    id: 22,
    image:
      "https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/endorsements_andrew_davies.jpg",
    name: "Andrew Davies",
    title: "Senior Fieldwork Manager, UK",
    quote:
      "The feedback was excellent and informative. I could identify with the strengths and areas for development that the assessment highlighted. The development plan recommended in the feedback report was also very useful and I am now using this in supervision with my manager.",
  },
];

export default testimonialData;
