import { useEffect } from "react";
import useAnalyticsPageViewSenderGA4 from "../utils/useAnalyticsPageViewSenderGA4";

import {
  Services,
  ServicesHeroBanner,
} from "../Components/ServiceOptionsAndPrices";

import React from "react";

function ServiceOptionsAndPrices() {
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Services");
  }, []);
  return (
    <section className="content">
      <ServicesHeroBanner />
      <Services />
    </section>
  );
}

export default ServiceOptionsAndPrices;
