import { useEffect } from "react";
import useAnalyticsPageViewSenderGA4 from "../utils/useAnalyticsPageViewSenderGA4";

import {
  AssessmentHeroBanner,
  AssessmentProcedure,
  FeedbackReportOverview,
} from "../Components/TheAssessment";

function HowCastWorks() {
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Assessment");
  }, []);
  return (
    <section className="content">
      <AssessmentHeroBanner />
      <AssessmentProcedure />
      <FeedbackReportOverview />
    </section>
  );
}

export default HowCastWorks;
