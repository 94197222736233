const Joi = require("joi");

function validateNumber(number, explain = "number") {
  const schema = Joi.number()
    .required()
    .messages({
      "number.pattern.base": `${explain} is not in the correct format`,
      "number.base": `${explain} is not the correct type`,
      "number.empty": `${explain} cannot be empty`,
    });

  //prevents the validation of numbers as strings - type checking
  if (typeof number !== "number")
    throw new TypeError(`${number} is not a number`);

  // validates number but also accepts a number as a string
  const result = schema.validate(number);

  if (result.error) {
    if (result.error.details[0].type === `number.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateNumber;
