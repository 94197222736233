import { BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import NavbarHome from "./NavbarHome";
import { useLocation, Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { FaShareSquare } from "react-icons/fa";
import { Cta } from "../Common";

import logo from "../../Assets/castLogo1.png";

const APP_URL = process.env.REACT_APP_APP_URL;

function HeaderDesktop({ shareTitle, shareText }) {
  const location = useLocation();

  return (
    <header className="home-page__header desktop--block">
      <div className="web-share">
        <RWebShare
          data={{
            text: shareText,
            url: APP_URL + location.pathname,
            title: shareTitle,
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button className="p--m p--bold">
            <FaShareSquare />
          </button>
        </RWebShare>
      </div>
      <div className="top-bar-container">
        <div className="logo">
          <Link to="/home">
            <img src={logo} alt="" />
          </Link>
        </div>
        <NavbarHome />
        <div className="desktop--block social-media-icons-container">
          <Cta text="Contact us" type="cta--header" to="/contact" />
          {/* SOCIAL MEDIA ICONS AND LINKS FOR FUTURE USE - DESKTOP */}
          {/*  <a
              href="https://twitter.com/"
              title="Follow us on Twitter (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsTwitter className="nav__media-icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/"
              title="Follow us on LinkedIn (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin className="nav__media-icon" />
            </a>
            <a
              href="https://www.instagram.com/"
              title="Follow us on Instagram (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram
                className="
              nav__media-icon"
              />
            </a> */}
        </div>
      </div>

      <div className="title--mobile mobile--block">
        <h1 className="h1">CAST - header desktop</h1>
      </div>
    </header>
  );
}

export default HeaderDesktop;
