// ========== Imports ========== //

import "./SubscribersTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import { getSubscribers } from "../../../logic";
import { DeleteSubscriber } from "../";

import { Pagination } from "../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("SubscribersTable");

// Receives the eventId and return the table

function SubscribersTable() {
  // ========== Hook consts ========== //

  const [subscribersData, setSubscribersData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return subscribersData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(subscriber, index) {
    // ========== JSX ========== //

    return (
      <tr key={subscriber.email}>
        <td>{index + 1}</td>
        <td>{subscriber.firstName}</td>
        <td>{subscriber.surname}</td>
        <td>{subscriber.email}</td>
        <td>{subscriber.dateSubscribed.slice(0, 10)}</td>
        <td className="buttons-td">
          <Link
            to={`/admin/subscribers/edit/${subscriber.email}`}
            className="link"
          >
             Edit
          </Link>{" "}
          /
          <DeleteSubscriber subscriberEmail={subscriber.email} />
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getSubscribers(sessionStorage.token, (error, subscribersArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        setSubscribersData(subscribersArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (subscribersData !== "") {
    return (
      <div className="subscribers-table">
        <h1>Manage Subscribers</h1>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Key</th>
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Date Subscribed</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={subscribersData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default SubscribersTable;

// TODO: refactor this helper into a component

async function printSubscribers() {
  function rowTable(subscriber, index) {
    // ========== JSX ========== //

    return (
      <tr key={subscriber.email}>
        <td>{index}</td>
        <td>{subscriber.firstName}</td>
        <td className="backoffice-table__description">{subscriber.surname}</td>
        <td>{subscriber.email}</td>
        <td>{subscriber.dateSubscribed}</td>
        <td className="buttons-td"></td>
      </tr>
    );
  }

  try {
    // const subscribersArray =
    getSubscribers(sessionStorage.token, (error, subscribersArray) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          toast.warn(error.message);
          logger.warn(error.message);
          if (error.message === "session timed out, please log in again") {
            delete sessionStorage.token;
            window.location.reload(false);
          }
        }
        return;
      }
      return subscribersArray.map(rowTable);
    });
  } catch (error) {
    toast.warn(error.message);
    logger.warn(error.message);
  }
}
