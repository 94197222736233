import { FormatError } from "errors";

import Joi from "joi";

function validateEmail(email, explain = "email") {
  const schema = Joi.string()
    .trim()
    .pattern(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
    .required()
    .max(254)
    .messages({
      "string.pattern.base": `${explain} is not in the correct format`,
      "string.base": `${explain} is not the correct type`,
      "string.empty": `${explain} cannot be empty`,
    });

  // validates email but also accepts a email as a string (control this in the previous TypeError check)
  const result = schema.validate(email);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else if (result.error.details[0].type === `string.empty`) {
      throw new FormatError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new FormatError(
        `error with ${explain}: email entered is not a valid email address`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateEmail;
