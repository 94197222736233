import { Cta } from "../Common";

import { useState } from "react";

import characterQualitiesIcon from "../../Assets/character-qualities-icon.png";
import supportiveDemandingIcon from "../../Assets/supportive-demanding-icon.png";
import recommendationsIcon from "../../Assets/recommendations_icon.png";
import supportiveDemandingGraph from "../../Assets/supportive-demanding-graph.png";
import characterQualitiesGraph from "../../Assets/character-qualities-graph.png";

import pdfDownloadIcon from "../../Assets/form_arrow.png";

import { HiChevronRight } from "react-icons/hi";

import "./FeedbackReportOverview.css";

function FeedbackReportOverview() {
  const [isActiveP1, setIsActiveP1] = useState(false);
  return (
    <section className="section feedback-report-overview">
      <div className="p--intro">
        <h2 className="h2">Feedback Report</h2>
        <p className="p--m p--left--mobile">
          The feedback report consists of graphs showing the results & a written
          summary to put the results into context. This includes the
          implications for specific roles & recommendations for development.
          <br />
          &nbsp;
        </p>
        <p className="p--m p--left--mobile">
          The feedback report can be broken down into three sections.
        </p>
      </div>

      <div className="graphs-container block">
        <img
          className="graph-icon--large"
          src={characterQualitiesIcon}
          alt="graph icon"
        />
        <h3 className="h3 p--left--mobile section-description">
          1. Purpose, Ownership, Perseverance, Growth Mindset (Carol Dweck,
          2016), & Resilience
        </h3>
        <div className="grid grid--1x2">
          <div className="graph-image-container">
            <p className="p--sm p--centered">
              Purpose, Ownership, Perseverance & Growth Mindset
            </p>
            <img
              className="graph-image"
              src={characterQualitiesGraph}
              alt="graph icon"
            />
          </div>
          <p className="p--m ">
            This graph shows the person’s scores in each of the 4 areas.
            Usually, scores between 4-5 are associated with solid strengths.
            Scores of 3-4 usually indicate areas to work on and develop. A score
            of less than 3 suggests a significant challenge and major area for
            development. Sometimes this may be associated with age and steady
            improvement may be possible if the person is in an entry-level role.
            However, a score of less than 3 would not suggest that the person is
            ready to hold a role with significant responsibility.{" "}
          </p>
        </div>
        <img
          className="graph-icon--large"
          src={supportiveDemandingIcon}
          alt="graph icon"
        />
        <h3 className="h3 p--left--mobile section-description section-description--2">
          2. Supportive / Demanding Scales for personal development & people
          management (Angela Duckworth, 2016)
        </h3>
        <div className="grid grid--1x2">
          <div>
            <p className="p--m ">
              The graph shows 4 quadrants, which can be considered as 4
              different approaches to assisting a person or a team in their
              development. Vast amounts of research have found that the
              supportive-demanding style is consistently the most effective for
              promoting development. Development is spurred both by demands &
              support. Demands push, focus, and stretch a person, while support
              encourages & enables. It is the balance of the two that leads to
              optimal development.
              <br />
              <br />
              With this approach positive development outcomes are most likely.
              This is consistently true whether we are looking at outcomes in
              personal & professional development, workplace engagement, &
              performance.
            </p>
          </div>
          <div className="graph-image-container supportive-demanding-graph-container">
            <img
              className="graph-image supportive-demanding-graph"
              src={supportiveDemandingGraph}
              alt="graph icon"
            />
          </div>
        </div>
        <div
          className={`collapsible ${
            isActiveP1 ? "collapsible--expanded" : null
          }`}
        >
          <header className="collapsible__header">
            <p
              className="p--m p--bold"
              onClick={() => setIsActiveP1(!isActiveP1)}
            >
              Continue reading to find out more about the
              graphs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <div
                  className="react-icon__container"
                  onClick={() => setIsActiveP1(!isActiveP1)}
                >
                  <HiChevronRight
                    className={`react-icon__icon--small react-icon__icon--chevronright ${
                      isActiveP1 && "react-icon__icon--active"
                    }`}
                  />
                </div>
              </span>{" "}
            </p>
          </header>
          <div className="collapsible__content">
            <p className="p--m ">
              &nbsp;
              <br />
              In the above graph, the X in the top left-hand corner represents
              the ideal position – the ability to be highly demanding & highly
              supportive. The blue diamond shape represents the person’s
              ‘parenting’ style, and their approach to their development. It can
              also reflect the approach to 1-1 work relationships, such as being
              a therapist, or salesperson. In this graph, the person’s score
              places them as reasonably well-balanced, highly supportive, and
              less demanding.
              <br />
              &nbsp;
            </p>
            <p className="p--m ">
              The green circle is still in the supportive-demanding quadrant.
              The person’s style is similar in being more supportive than
              demanding and it is not so well-developed. This would cause
              challenges in managing a group or team of people. The green dotted
              line towards the X shows the developmental pathway. This insight
              can lead to a focused development plan to improve the potential
              for a management role.
            </p>
          </div>
        </div>

        <img
          className="graph-icon--large"
          src={recommendationsIcon}
          alt="recommendations icon"
        />
        <h3 className="h3 p--left--mobile section-description">
          3. Recommendations for ongoing personal & professional development
        </h3>
        <p className="p--m ">
          A summary is given to put the graphs into context and explain the
          implications for different roles and levels of responsibility. Each
          summary report will help identify where the person is right now in
          their development and the potential pathways for future development in
          the short, medium, and long term. For example, is the person more
          suited to leadership and management roles, specialist technical roles,
          or both? The recommendations will help identify approaches to support
          development. Here is an example of some recommendations,
        </p>
      </div>
      <div className="recommendations">
        <div className="recommendations-card block--primary">
          <div className="title-block">
            <h2 className="h3 p--centered">Example Recommendations</h2>
          </div>
          <div className="paragraph--closing">
            <ol className="p--sm recommendations-list">
              <li className="">
                During the next few months, clarify the long-term plan with
                details, such as a timeline for various parts of the plan to be
                achieved.
              </li>
              <li className="">
                As Alex has strong purpose, perseverance, and resilience but can
                also be undemanding with others, she may over-compensate for
                others by taking on too much herself. Focusing on this in her
                1-1 meetings will be helpful - setting goals and reviewing
                regularly.
              </li>
              <li className="">
                Work on being more consistently task-focused with others and
                herself. Set clear goals for herself and her colleagues. Review
                progress and avoid allowing excuses if tasks do not get done.
              </li>
              <li className="">
                Maintain the supportive part of her approach but use this more
                in terms of holding people accountable and sticking with
                appropriate demands.
              </li>
              <li className="">
                Consider keeping a reflective journal to record, monitor, and
                reflect upon – how well she manages boundaries, completes tasks
                on time, and how her work/life balance feels from week to week.
              </li>
              <li className="">
                As Alex is especially clear-thinking, it will be helpful to
                explore how she arrives at her views and to consider other
                possibilities. She seems open to the potential learning
                involved.
              </li>
              <li className="">
                Use supervision and/or a mentoring process to help keep a clear
                focus on the issues above and her development in general.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="pdf-download-icon-container">
        <a
          href="https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/KerryEdmondsCASTReport.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfDownloadIcon} alt="" />
        </a>
        <a
          href="https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/KerryEdmondsCASTReport.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="p--m">Download an example of a full report</p>
        </a>
      </div>
      <Cta text="Make an enquiry" type="cta--accent" to="/contact" />
    </section>
  );
}

export default FeedbackReportOverview;
