import { Cta } from "../../Components/Common";

import { LuCheck } from "react-icons/lu";

import "./WhoWeAre.css";

function WhoWeAre() {
  return (
    <section className="section who-we-are-section">
      <div className="grid grid--1x2 grid--1x2--12">
        <div>&nbsp;</div>
        <div>
          <h2 className="h2">Leadership Strategist</h2>
        </div>
        <div className="headshot-image-container">
          <img
            src="https://cast-app-bucket.s3.eu-west-2.amazonaws.com/base/patrick_headshot_2024.png"
            alt="patrick tomlinson"
          />
        </div>
        <div className="text-container">
          <div className="title-container title-container--patrick">
            <h3 className="h3">Patrick Tomlinson</h3>
            <p className="p--m">
              Organizational & Professional Development Consultant. The primary
              goal of Patrick’s work is the development of people &
              organizations.
            </p>
          </div>
          <ul className="p--m">
            <li>
              Over 30 years of experience in leadership – professional,
              workforce, & organizational development.
            </li>
            <li>Vast experience in staff selection & development.</li>
            <li>Over 8 years of research on the CAST tool.</li>
            <li>
              Has conducted studies & research on staff retention & helped
              organizations significantly reduce the costs of ineffective staff
              selection.
            </li>
            <li>
              Has helped organizations save £100Ks & by improving outcomes
              in,&nbsp;
              <br /> <br />
              <ul className="tick-list">
                <li className="p--m">
                  <LuCheck className="check-icon" />
                  <span>staff selection</span>
                </li>
                <li className="p--m">
                  <LuCheck className="check-icon" />
                  <span>development</span>
                </li>
                <li className="p--m">
                  <LuCheck className="check-icon" />
                  <span>performance</span>
                </li>
                <li className="p--m">
                  <LuCheck className="check-icon" />
                  <span>retention</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <Cta text="Contact us" type="cta--accent" to="/contact" />
    </section>
  );
}

export default WhoWeAre;
