// ========== imports ========== //
import "./ScoreResponse.css";

import Loggito from "../../../utils/Loggito";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { getFormResponse, updateSubscriber } from "../../../logic";
import { schemaEditSubscriberForm } from "../../../validators";

import { Spinner } from "../../Common";
// ========== Page ========== //

function ScoreResponse() {
  // ========== Hook consts ========== //

  const [responseData, setResponseData] = useState();
  const [firstName, setFirstName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [dateSubscribed, setDateSubscribed] = useState();

  const params = useParams();
  const navigate = useNavigate();

  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  let emailDB = params.email;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getFormResponse(
        sessionStorage.token,
        emailDB,
        (error, responseDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              // TODO: test this navigate
              navigate("/admin/formresponsetable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/formresponsetable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setResponseData(responseDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/formresponsetable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);
  // ========== other consts ========== //

  /*   const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaEditSubscriberForm) }); */

  const logger = new Loggito("Score Response");
  // ========== useEffects ========== /

  /* const handleUpdateSubscriber = (data) => {
    // TODO: this is called on page load - this needs to be fixed to be called only when button is pressed
    const subscriberDataToUpdate = {};
    subscriberDataToUpdate.firstName = data.firstName;
    subscriberDataToUpdate.surname = data.surname;
    subscriberDataToUpdate.email = data.email;
    subscriberDataToUpdate.dateSubscribed = data.dateSubscribed;
    data.dateSubscribed
      ? (subscriberDataToUpdate.dateSuibscribed = new Date(data.dateSubscribed))
      : (subscriberDataToUpdate.dateSuibscribed = null);

    try {
      updateSubscriber(
        sessionStorage.token,
        route,
        subscriberDataToUpdate,
        (error) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }

          // route = kebabFileName(data.title);
          // navigate(`/`);
          // const newRoute = kebabFileName(data.tile);
          const newRoute = `/admin/subscribers/edit/${subscriberDataToUpdate.email}`;
          navigate(newRoute);
          // navigate(`/admin/news/edit/${newRoute}`);

          toast.success("Subscriber updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };
  */

  const handleFormSubmit = (data) => {
    // handleUpdateSubscriber(data);
  };
  // ========== jsx ========== //
  if (responseData) {
    return (
      <div className="">
        {/* {responseData.email} */}
        <form
          // ref={form}
          // onSubmit={() => saveFormData()}
          className="report-form"
          noValidate={true}
        >
          <div className="grid grid--1x2">
            <p className="p--m">{`Full Name: ${responseData.fullName}`}</p>
            <p>&nbsp;</p>
            <p className="p--m">{`Gender: ${responseData.gender}`}</p>
            <p>&nbsp;</p>
            <p className="p--m">{`Email: ${responseData.email}`}</p>
            <p>&nbsp;</p>
            <p className="p--m">{`Organisation: ${responseData.organisation}`}</p>
            <p>&nbsp;</p>
            <p className="p--m">{`Written Response: ${responseData.writtenResponse}`}</p>
            <div>
              <p className="p--m">
                Select score (0 being low and 3 being high):
              </p>
              <input
                type="radio"
                id="writtenResponse0"
                name="writtenResponse"
                value={0}
              />
              <label htmlFor="writtenResponse0">0</label>
              <br />
              <input
                type="radio"
                id="writtenResponse1"
                name="writtenResponse"
                value={1}
              />
              <label htmlFor="writtenResponse1">1</label>
              <br />
              <input
                type="radio"
                id="writtenResponse2"
                name="writtenResponse"
                value={2}
              />
              <label htmlFor="writtenResponse2">2</label>
              <br />
              <input
                type="radio"
                id="writtenResponse3"
                name="writtenResponse"
                value={3}
              />
              <label htmlFor="writtenResponse3">3</label>
            </div>
            <p className="p--m">{`Stress Level: ${responseData.stressLevel}`}</p>
            <p>&nbsp;</p>
            <p className="p--m">{`Subscribed: ${responseData.subscribe}`}</p>
            <p>&nbsp;</p>
          </div>
          <label className="p--m" htmlFor="recommendations">
            Recommendations:
          </label>
          <textarea id="writtenResponse" className="p--m" />
          {formDataBeingSent ? (
            <button type="button" className="cta cta--accent--clicked">
              Submit
            </button>
          ) : null}
          {!formDataBeingSent ? (
            <button type="submit" className="cta cta--accent">
              Submit
            </button>
          ) : null}
        </form>
      </div>
    );
  } else return <Spinner />;
}

export default ScoreResponse;
