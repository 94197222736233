import Joi from "joi";

const schemaAddNewsForm = Joi.object({
  title: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid title",
    "string.empty": "please enter a title",
  }),
  status: Joi.string()
    .trim()
    .allow("")
    .valid("", "draft", "published", "inactive")
    .min(1)
    .messages({
      "string.base": "please select a valid status",
      "string.empty": "please select a status",
    }),
  /* category: Joi.string()
    .trim()
    .allow("")
    .valid("", "Category 1", "Category 2", "Category 3", "Category 4")
    .min(1)
    .messages({
      "string.base": "please select a valid category",
      "string.empty": "please select a category",
    }), */
  date: Joi.string().trim().allow("").messages({
    "string.base": "please select a valid date",
    "string.empty": "please select a date",
  }),
  editorsPick: Joi.boolean(),
  tags: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please add valid tags separated by a comma",
    "string.empty": "please add a tag",
  }),
  video: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid YouTube url",
    "string.empty": "please enter a YouTube url",
  }),
  /* noteToSubscribers: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please write a valid note to subscribers",
    "string.empty": "please add a note to subscribers",
  }), */
});

export default schemaAddNewsForm;
