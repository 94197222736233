import "./CookiesPolicy.css";

import { Cta } from "../Common";
import { toast } from "react-toastify";

import { useCookies } from "react-cookie";

// function CookiesPolicy({ context: { modifyCookie, removeCookie } }) {
function CookiesPolicy() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "cookieConsentCookie",
  ]);
  const modifyCookie = (name, value, expireDays) => {
    const today = new Date();
    const expireDate = new Date(today);
    // change the number to set the expiry date in days from the present
    expireDate.setDate(expireDate.getDate() + expireDays);

    // used to modify a value for a specific cookie, or create a new cookie, can also add options arguments to set expiration etc...
    setCookie(name, value, { expires: expireDate });
  };

  return (
    <div className="section cookies-policy">
      <div className="content block">
        <h1 className="h1">COOKIE POLICY</h1>
        <p className="p--m">
          This cookie policy (&#8220;Policy&#8221;) describes what cookies are
          and how and they&#8217;re being used by the{" "}
          <a href="https://www.castassessment.com">
            https://www.castassessment.com
          </a>{" "}
          website (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of
          its related products and services (collectively,
          &#8220;Services&#8221;). This Policy is a legally binding agreement
          between you (&#8220;User&#8221;, &#8220;you&#8221; or
          &#8220;your&#8221;) and this Website operator (&#8220;Operator&#8221;,
          &#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;).
        </p>
        <p className="p--m">
          If you are entering into this agreement on behalf of a business or
          other legal entity, you represent that you have the authority to bind
          such entity to this agreement, in which case the terms
          &#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221; shall
          refer to such entity. If you do not have such authority, or if you do
          not agree with the terms of this agreement, you must not accept this
          agreement and may not access and use the Website and Services. You
          should read this Policy so you can understand the types of cookies we
          use, the information we collect using cookies and how that information
          is used. It also describes the choices available to you regarding
          accepting or declining the use of cookies. For further information on
          how we use, store and keep your personal data secure, see our{" "}
          <a href="https://www.castassessment.com/privacypolicy/">
            privacy policy
          </a>
          .
        </p>
        <div className="wpembed-toc">
          <h3 className="h3">Table of contents</h3>
          <ol className="wpembed-toc">
            <li className="p--m">
              <a href="#what-are-cookies">What are cookies?</a>
            </li>
            <li className="p--m">
              <a href="#what-type-of-cookies-do-we-use">
                What type of cookies do we use?
              </a>
            </li>
            <li className="p--m">
              <a href="#what-are-your-cookie-options">
                What are your cookie options?
              </a>
            </li>
            <li className="p--m">
              <a href="#changes-and-amendments">Changes and amendments</a>
            </li>
            <li className="p--m">
              <a href="#acceptance-of-this-policy">Acceptance of this policy</a>
            </li>
            <li className="p--m">
              <a href="#contacting-us">Contacting us</a>
            </li>
            <li className="p--m">
              <a href="#manage-cookies">Manage cookies</a>
            </li>
          </ol>
        </div>
        <h2 className="h2" id="what-are-cookies">
          What are cookies?
        </h2>
        <p className="p--m">
          Cookies are small pieces of data stored in text files that are saved
          on your computer or other devices when websites are loaded in a
          browser. They are widely used to remember you and your preferences,
          either for a single visit (through a &#8220;session cookie&#8221;) or
          for multiple repeat visits (using a &#8220;persistent cookie&#8221;).
        </p>
        <p className="p--m">
          Session cookies are temporary cookies that are used during the course
          of your visit to the Website, and they expire when you close the web
          browser.
        </p>
        <p className="p--m">
          Persistent cookies are used to remember your preferences within our
          Website and remain on your desktop or mobile device even after you
          close your browser or restart your computer. They ensure a consistent
          and efficient experience for you while visiting the Website and
          Services.
        </p>
        <p className="p--m">
          Cookies may be set by the Website (&#8220;first-party cookies&#8221;),
          or by third parties, such as those who serve content or provide
          advertising or analytics services on the Website (&#8220;third party
          cookies&#8221;). These third parties can recognize you when you visit
          our website and also when you visit certain other websites.
        </p>
        <h2 className="h2" id="what-type-of-cookies-do-we-use">
          What type of cookies do we use?
        </h2>
        <h3 className="h3">Necessary cookies</h3>
        <p className="p--m">
          Necessary cookies allow us to offer you the best possible experience
          when accessing and navigating through our Website and using its
          features. For example, these cookies let us recognize that you have
          created an account and have logged into that account to access the
          content.
        </p>
        <h3 className="h3">Functionality cookies</h3>
        <p className="p--m">
          Functionality cookies let us operate the Website and Services in
          accordance with the choices you make. For example, we will recognize
          your username and remember how you customized the Website and Services
          during future visits.
        </p>
        <h3 className="h3">Social media cookies</h3>
        <p className="p--m">
          Third party cookies from social media sites (such as Facebook,
          Twitter, etc) let us track social network users when they visit or use
          the Website and Services, or share content, by using a tagging
          mechanism provided by those social networks.
        </p>
        <p className="p--m">
          These cookies are also used for event tracking and remarketing
          purposes. Any data collected with these tags will be used in
          accordance with our and social networks&#8217; privacy policies. We
          will not collect or share any personally identifiable information from
          the user.
        </p>
        <h2 className="h2" id="what-are-your-cookie-options">
          What are your cookie options?
        </h2>
        <p className="p--m">
          If you don&#8217;t like the idea of cookies or certain types of
          cookies, you can change your browser&#8217;s settings to delete
          cookies that have already been set and to not accept new cookies.
          Visit{" "}
          <a
            href="https://www.internetcookies.com"
            target="_blank"
            rel="nofollow noreferrer noopener external"
          >
            internetcookies.com
          </a>{" "}
          to learn more about how to do this.
        </p>
        <p className="p--m">
          Please note, however, that if you delete cookies or do not accept
          them, you might not be able to use all of the features the Website and
          Services offer.
        </p>
        <h2 className="h2" id="changes-and-amendments">
          Changes and amendments
        </h2>
        <p className="p--m">
          We reserve the right to modify this Policy or its terms related to the
          Website and Services at any time at our discretion. When we do, we
          will revise the updated date at the bottom of this page. We may also
          provide notice to you in other ways at our discretion, such as through
          the contact information you have provided.
        </p>
        <p className="p--m">
          An updated version of this Policy will be effective immediately upon
          the posting of the revised Policy unless otherwise specified. Your
          continued use of the Website and Services after the effective date of
          the revised Policy (or such other act specified at that time) will
          constitute your consent to those changes.
        </p>
        <h2 className="h2" id="acceptance-of-this-policy">
          Acceptance of this policy
        </h2>
        <p className="p--m">
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services. This cookie policy was created with the help of{" "}
          <a
            href="https://www.websitepolicies.com/cookie-policy-generator"
            target="_blank"
            rel="noreferrer noopener"
          >
            WebsitePolicies
          </a>
          .
        </p>
        <h2 className="h2" id="contacting-us">
          Contacting us
        </h2>
        <p className="p--m">
          If you have any questions, concerns, or complaints regarding this
          Policy or the use of cookies, we encourage you to contact us using the
          details below:
        </p>
        <p className="p--m">
          <a href="https://www.castassessment.com/contact/">
            https://www.castassessment.com/contact/
          </a>
        </p>
        <p className="p--m">This document was last updated on July 22, 2024</p>
        <p className="madewith">
          <a
            href="https://www.websitepolicies.com/cookie-policy-generator?via=madewithbadge"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              width="200"
              height="25"
              alt="Made with WebsitePolicies cookie policy generator"
              src="https://cdn.websitepolicies.io/img/badge.png"
            />
          </a>
        </p>
        <h2 className="h2" id="manage-cookies">
          Manage your cookie preferences
        </h2>
        <p className="p--m">
          You can modify your prefences here anytime by clicking on the icon at
          the bottom left of the screen to open the cookie preferences window.
        </p>
      </div>
      <Cta text="Get in touch" type="cta--accent" to="/contact" />
    </div>
  );
}

export default CookiesPolicy;
