// ========== imports ========== //
import "./EditSubscriber.css";

import Loggito from "../../../utils/Loggito";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { getSubscriber, updateSubscriber } from "../../../logic";
import { schemaEditSubscriberForm } from "../../../validators";

import { Spinner } from "../../Common";
// ========== Page ========== //

function EditSubscriber() {
  // ========== Hook consts ========== //

  const [subscriberData, setSubscriberData] = useState();
  const [firstName, setFirstName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [dateSubscribed, setDateSubscribed] = useState();

  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getSubscriber(
        sessionStorage.token,
        route,
        (error, subscriberDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              // TODO: test this navigate
              navigate("/admin/subscriberstable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/subscribersstable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setSubscriberData(subscriberDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/subscriberstable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);
  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaEditSubscriberForm) });

  const logger = new Loggito("EditSubscriber");
  // ========== useEffects ========== /

  const handleUpdateSubscriber = (data) => {
    // TODO: this is called on page load - this needs to be fixed to be called only when button is pressed
    const subscriberDataToUpdate = {};
    subscriberDataToUpdate.firstName = data.firstName;
    subscriberDataToUpdate.surname = data.surname;
    subscriberDataToUpdate.email = data.email;
    subscriberDataToUpdate.dateSubscribed = data.dateSubscribed;
    data.dateSubscribed
      ? (subscriberDataToUpdate.dateSuibscribed = new Date(data.dateSubscribed))
      : (subscriberDataToUpdate.dateSuibscribed = null);

    try {
      updateSubscriber(
        sessionStorage.token,
        route,
        subscriberDataToUpdate,
        (error) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }

          const newRoute = `/admin/subscribers/edit/${subscriberDataToUpdate.email}`;
          navigate(newRoute);

          toast.success("Subscriber updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const handleFormSubmit = (data) => {
    handleUpdateSubscriber(data);
  };

  // ========== jsx ========== //
  if (subscriberData) {
    return (
      <div className="edit-subscriber">
        <div className="content--admin">
          <h2 className="h2">Edit Subscriber</h2>
          <h3 className="h3">Please complete the details below:</h3>
          <form
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            className="edit-subscriber-form"
            noValidate={true}
          >
            <div>{/* TODO: DATE */}</div>
            <div>
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                placeholder=""
                name="firstName"
                defaultValue={subscriberData.firstName}
                {...register("firstName", {})}
              />
              {errors.firstName && (
                <p className="input__error-message">
                  {errors.firstName?.message}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="surname">surname:</label>
              <input
                type="text"
                placeholder=""
                name="surname"
                defaultValue={subscriberData.surname}
                {...register("surname", {})}
              />
              {errors.surname && (
                <p className="input__error-message">
                  {errors.surname?.message}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                placeholder=""
                name="email"
                defaultValue={subscriberData.email}
                {...register("email", {})}
              />
              {errors.email && (
                <p className="input__error-message">{errors.email?.message}</p>
              )}
            </div>

            <div>
              <label
                className="p--medium label--select"
                htmlFor="dateSubscribed"
              >
                {`Choose a date: (Current date is ${subscriberData.dateSubscribed.slice(
                  0,
                  10
                )} at ${subscriberData.dateSubscribed.slice(11, 16)}) `}
              </label>
              <input
                type="datetime-local"
                placeholder=""
                {...register("dateSubscribed")}
              />

              {errors.dateSubscribed && (
                <p className="input__error-message">
                  {errors.dateSubscribed?.message}
                </p>
              )}
            </div>

            <button type="submit" className="cta cta--accent">
              Save
            </button>
          </form>
        </div>
      </div>
    );
  } else return <Spinner />;
}

export default EditSubscriber;
