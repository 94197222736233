console.log(
  "%c Loggito %c v0.1",
  "font-size: 16px; color: yellow; background-color: dodgerblue; display: block; border: 1px solid black;",
  "font-size: 10px;"
);

class Loggito {
  static DEBUG = 0;
  static INFO = 1;
  static WARN = 2;
  static ERROR = 3;
  static FATAL = 4;

  static level = 0;

  constructor(target) {
    this.target = target;
  }

  fatal(message) {
    Loggito.level <= Loggito.FATAL &&
      console.log(
        `%cFATAL ${new Date().toISOString()} ${this.target} ${message}`,
        "background-color: red; color: white"
      );
  }

  error(message) {
    Loggito.level <= Loggito.ERROR &&
      console.log(
        `%cERROR ${new Date().toISOString()} ${this.target} ${message}`,
        "color: red"
      );
  }

  warn(message) {
    Loggito.level <= Loggito.WARN &&
      console.log(
        `%cWARN ${new Date().toISOString()} ${this.target} ${message}`,
        "color: orange"
      );
  }

  info(message) {
    Loggito.level <= Loggito.INFO &&
      console.log(
        `%cINFO ${new Date().toISOString()} ${this.target} ${message}`,
        "color: blue"
      );
  }

  debug(message) {
    Loggito.level <= Loggito.DEBUG &&
      console.log(
        `%cDEBUG ${new Date().toISOString()} ${this.target} ${message}`,
        "color: green"
      );
  }
}

export default Loggito;
