const { FormatError } = require("errors");

const Joi = require("joi");

// validate string validates a string that can be empty

function validateObjectIdString(text, explain = "object ID") {
  const schema = Joi.string()
    .trim()
    .required()
    .regex(/^[a-f\d]{24}$/i)
    .min(24)
    .max(24)
    .messages({
      "string.base": `${explain} is not the correct type type`,
      "string.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(text);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new FormatError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateObjectIdString;
