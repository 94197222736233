import { useState, useEffect } from "react";
import "./Comments.css";
import { AddMainComment, MainComments } from ".";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { getCommentsTotal } from "../../../logic";

function Comments({ route }) {
  const [commentSectionIsOpen, setCommentSectionIsOpen] = useState(false);
  const [commentsTotal, setCommentsTotal] = useState(false);

  const logger = new Loggito("Comments");

  useEffect(() => {
    loadComments();
  }, []);

  const loadComments = () => {
    try {
      return getCommentsTotal(route, (error, commentsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setCommentsTotal(commentsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const handleOpenCommentSection = () => {
    setCommentSectionIsOpen(!commentSectionIsOpen);
  };
  return (
    <div className="content">
      {commentsTotal === 1 ? (
        <p className="p--m p--centered">
          This article has <span className="p--bold">{commentsTotal}</span>{" "}
          comment.
        </p>
      ) : null}
      {commentsTotal > 1 ? (
        <p className="p--m p--centered">
          This article has <span className="p--bold">{commentsTotal}</span>{" "}
          comments.
        </p>
      ) : null}
      {commentsTotal === 0 ? (
        <p className="p--m p--centered">This article has no comments yet.</p>
      ) : null}
      {commentSectionIsOpen ? (
        <div className="comments-section">
          <button
            className="cta cta--outline"
            type="button"
            onClick={handleOpenCommentSection}
          >
            Close comments
          </button>
          <AddMainComment route={route} type="main" />
          <MainComments route={route} />
        </div>
      ) : (
        <div className="comments-section">
          <button
            className="cta cta--outline"
            type="button"
            onClick={handleOpenCommentSection}
          >
            View comments ...
          </button>
        </div>
      )}
    </div>
  );
}

export default Comments;
