import React, { useState, useEffect } from "react";
import "./SubComments.css";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { getCommentsClient } from "../../../logic";

function SubComments({ route, mainCommentId }) {
  const [commentsData, setCommentsData] = useState();

  const logger = new Loggito();

  useEffect(() => {
    loadComments();
  }, []);

  const loadComments = () => {
    try {
      return getCommentsClient(route, mainCommentId, (error, commentsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setCommentsData(commentsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  return (
    <div className="sub-comments">
      <ul className="list ">
        {commentsData
          ? commentsData.map((comment) => {
              return (
                <li className="sub-comment" key={comment.id}>
                  <p className="name">{`${comment.name} says...`}</p>
                  <p className="comment p--m">{comment.comment}</p>
                  <p className="date">{comment.dateString}</p>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}

export default SubComments;
