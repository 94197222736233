import { Cta } from "../Common";

import "./ContactHeroBanner.css";

function ContactHeroBanner() {
  return (
    <section className="section block--primary contact-hero-banner">
      <div className="flipped-elements">
        <h1 className="circles-hero-banner-title">
          CAST <br />
          Contact
        </h1>
        <p className="p--m title-paragraph">
          Want to find out more? Send us an email and we'll be in touch soon.
        </p>
      </div>
    </section>
  );
}

export default ContactHeroBanner;
