// ========== imports ========== //

import React, { useRef, useState, useEffect } from "react";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";
// ========== Page ========== //

/* const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY; */

function Section2({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const saveFormData = async () => {
    const values = {
      stressLevel: form.current.stressLevel.value,
      positivityLevel: form.current.positivityLevel.value,
    };

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(values, function (error) {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        toast.success("Your answers have been saved");
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section 2");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={() => saveFormData()}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section 2 - Stress and Positivity</h2>
        <p className="p--m">
          Please think about how you are feeling, right now, in this very
          moment.
          <br />
          &nbsp;
        </p>
      </div>
      <div className="cast-form__question-container">
        <div className="cast-form__question cast-form__question--radio">
          <p className="p--m cast-form__question__qtext">
            What is you current level of stress (how do you feel right now)?
          </p>
          <div className="radio-options">
            <p className="p--sm">low</p>
            <div>
              <label htmlFor="stressLevel0" className="p--sm">
                0
              </label>
              <input
                type="radio"
                id="stressLevel0"
                name="stressLevel"
                value={0}
              />
            </div>
            <div>
              <label htmlFor="stressLevel1" className="p--sm">
                1
              </label>
              <input
                type="radio"
                id="stressLevel1"
                name="stressLevel"
                value={1}
              />
            </div>
            <div>
              <label htmlFor="stressLevel2" className="p--sm">
                2
              </label>
              <input
                type="radio"
                id="stressLevel2"
                name="stressLevel"
                value={2}
              />
            </div>
            <div>
              <label htmlFor="stressLevel3" className="p--sm">
                3
              </label>
              <input
                type="radio"
                id="stressLevel3"
                name="stressLevel"
                value={3}
              />
            </div>
            <div>
              <label htmlFor="stressLevel4" className="p--sm">
                4
              </label>
              <input
                type="radio"
                id="stressLevel4"
                name="stressLevel"
                value={4}
              />
            </div>
            <div>
              <label htmlFor="stressLevel5" className="p--sm">
                5
              </label>
              <input
                type="radio"
                id="stressLevel5"
                name="stressLevel"
                value={5}
              />
            </div>
            <div>
              <label htmlFor="stressLevel6" className="p--sm">
                6
              </label>
              <input
                type="radio"
                id="stressLevel6"
                name="stressLevel"
                value={6}
              />
            </div>
            <div>
              <label htmlFor="stressLevel7" className="p--sm">
                7
              </label>
              <input
                type="radio"
                id="stressLevel7"
                name="stressLevel"
                value={7}
              />
            </div>
            <div>
              <label htmlFor="stressLevel8" className="p--sm">
                8
              </label>
              <input
                type="radio"
                id="stressLevel8"
                name="stressLevel"
                value={8}
              />
            </div>
            <div>
              <label htmlFor="stressLevel9" className="p--sm">
                9
              </label>
              <input
                type="radio"
                id="stressLevel9"
                name="stressLevel"
                value={9}
              />
            </div>
            <div>
              <label htmlFor="stressLevel10" className="p--sm">
                10
              </label>
              <input
                type="radio"
                id="stressLevel10"
                name="stressLevel"
                value={10}
              />
            </div>
            <p className="p--sm">high</p>
          </div>
        </div>
      </div>
      <div className="cast-form__question-container">
        <div className="cast-form__question cast-form__question--radio">
          <p className="p--m cast-form__question__qtext">
            What is you current level of positivity (how do you feel right now?)
          </p>
          <div className="radio-options">
            <p className="p--sm">low</p>
            <div>
              <label htmlFor="positivityLevel0" className="p--sm">
                0
              </label>
              <input
                type="radio"
                id="positivityLevel0"
                name="positivityLevel"
                value={0}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel1" className="p--sm">
                1
              </label>
              <input
                type="radio"
                id="positivityLevel1"
                name="positivityLevel"
                value={1}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel2" className="p--sm">
                2
              </label>
              <input
                type="radio"
                id="positivityLevel2"
                name="positivityLevel"
                value={2}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel3" className="p--sm">
                3
              </label>
              <input
                type="radio"
                id="positivityLevel3"
                name="positivityLevel"
                value={3}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel4" className="p--sm">
                4
              </label>
              <input
                type="radio"
                id="positivityLevel4"
                name="positivityLevel"
                value={4}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel5" className="p--sm">
                5
              </label>
              <input
                type="radio"
                id="positivityLevel5"
                name="positivityLevel"
                value={5}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel6" className="p--sm">
                6
              </label>
              <input
                type="radio"
                id="positivityLevel6"
                name="positivityLevel"
                value={6}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel7" className="p--sm">
                7
              </label>
              <input
                type="radio"
                id="positivityLevel7"
                name="positivityLevel"
                value={7}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel8" className="p--sm">
                8
              </label>
              <input
                type="radio"
                id="positivityLevel8"
                name="positivityLevel"
                value={8}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel9" className="p--sm">
                9
              </label>
              <input
                type="radio"
                id="positivityLevel9"
                name="positivityLevel"
                value={9}
              />
            </div>
            <div>
              <label htmlFor="positivityLevel10" className="p--sm">
                10
              </label>
              <input
                type="radio"
                id="positivityLevel10"
                name="positivityLevel"
                value={10}
              />
            </div>
            <p className="p--sm">high</p>
          </div>
        </div>
      </div>

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(Section2);
