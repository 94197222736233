const { FormatError } = require("errors");

const Joi = require("joi").extend(require("@joi/date"));

function validateDate(date, explain = "date") {
  const schema = Joi.date();
  // .format('YYYY-MM-DD').utc();

  const result = schema.validate(date);

  if (result.error) {
    throw new FormatError(
      `error with ${explain}: ${result.error.details[0].message}`
    );
  }

  return `${explain} validated successfully`;
}

export default validateDate;
