import { Cta } from "../Common";

import logo from "../../Assets/castLogo1.png";
import background2 from "../../Assets/workforce_1_transparent_v2 1.png";

import "./LandingTitle.css";

function LandingTitle(props) {
  return (
    <div className="center-container grid grid--1x2 landing-title">
      <div className="grid-cell grid-cell--left">
        <img src={logo} alt="" />
        <h1 className="h1">
          <i>character assessment & selection tool</i>
        </h1>
        <p className="title-paragraph">
          CAST gets under the surface to identify a person's character, values,
          and attributes, that are critical to long-term performance and
          development
        </p>
        <Cta text="Contact us" type="cta--accent" to="/contact" />
      </div>
      <div className="grid-cell">
        <img src={background2} alt="" />
      </div>
    </div>
  );
}

export default LandingTitle;
