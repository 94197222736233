// ========== imports ========== //
import "./TextWrapImage.css";

import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";
// ========== Page ========== //

function TextWrapImage() {
  // ========== Hook consts ========== //

  // ========== other consts ========== //

  const logger = new Loggito("TextWrapImage");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="">
      <div>
        <div>
          {/* important that the image comes first so that it is at the top on smaller screens */}
          <img
            className="text-wrap-image__img"
            src="https://media.geeksforgeeks.org/wp-content/uploads/20190808143838/logsm.png"
            alt="Longtail boat in Thailand"
          />
        </div>

        <p className="text-wrap-image__p">
          How many times were you frustrated while looking out for a good...
        </p>
      </div>
    </div>
  );
}

export default withContext(TextWrapImage);
