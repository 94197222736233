import { Cta } from "../Common";
import goodNews from "../../Assets/good-news-celebration.png";

import "./TheGoodNews.css";

function TheGoodNews() {
  return (
    <section className="section the-good-news">
      <div class="wrapper grid grid--1x2">
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            The feedback was excellent and informative. I highly recommend the
            assessment for anyone seeking to understand their strengths and
            areas for development and to help them set clear development goals
            for the future.
            <span className="username">Senior Fieldwork Manager, UK</span>
          </p>
        </div>
        <div class="speechbubble speechbubble--grid">
          <p className="p--sm p--centered">
            I believe in the CAST assessment to the point that the report
            generated from an assessment of a candidate for a key role in our
            organisation would have a big part to play in the decision-making
            process on appointments and professional development plans.
            <span className="username">Director, Ireland</span>
          </p>
        </div>
      </div>
      <div className="title-block">
        <h2 className="h2">The Good News!</h2>
        <div className="good-news-image-container centered-content">
          <img src={goodNews} alt="good news" />
        </div>
      </div>
      <div className="mixed-elements-container">
        <p className="p--m p--centered">
          <b>
            <span className="p--m">Everyone is capable of development</span>
          </b>
          <br />
          with a focused development plan
          <br />
          and continuous purposeful & deliberate practice.
        </p>
        <p className="p--m p--block">
          <span className="good-news-quote">
            “While attributes are part of everyone’s circuitry, they’re not
            immutable. They can be tweaked and modified… If you want to
            understand human performance—yours and others’—the first step is to
            understand attributes.”{" "}
            <span className="p--bold p--sm">
              &nbsp;&nbsp;&nbsp;&nbsp;(Diviney, 2021)
            </span>
          </span>
        </p>
      </div>
      <Cta text="Contact us" type="cta--accent" to="/contact" />
    </section>
  );
}

export default TheGoodNews;
