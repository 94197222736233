import { Cta } from "../Common";
import theRightPeople from "../../Assets/the-right-people.png";

import "./TheRightPeople.css";

function TheRightPeople() {
  return (
    <section className="section the-right-people">
      <div className="title-block">
        <h2 className="h2">Putting the Right People in the Right Roles</h2>
      </div>
      <div className="paragraph--closing">
        <p className="p--m">
          We support organizations and professionals to grow and improve
          performance by selecting and investing in the right people for the
          right role. The Core Aim of CAST is to <b>Assess Character</b> so that
          this can be considered alongside skills. In especially challenging
          occupations only the combination of character with skills will enable
          effective performance.
        </p>
        <p className="p--m">
          Performance is strongly associated with <b>Employee Engagement</b>{" "}
          (Gallup (2024) State of the Global Workforce). Managers are the key
          influence on employee engagement. CAST helps to improve engagement and
          performance by improving the selection and development of managers.
        </p>
        <p className="p--m">
          "Many of the organizations Gallup has studied did not start with high
          levels of engagement. Becoming a highly engaged organization was an
          intentional effort of leadership over several years. Leaders who build
          strong and resilient cultures focus on a few things that set them
          apart from other organizations: They put a high priority on manager
          hiring and development."
        </p>
        <div className="the-right-people-image-container">
          <img src={theRightPeople} alt="The Right People" />
        </div>
        <p className="p--m">
          “The Old Adage “People are your most important asset” is wrong. People
          are not your most important asset. The right people are. Whether
          someone is the “right person” has more to do with character traits and
          innate capabilities than with specific knowledge, background, or
          skills.” (Jim Collins, 2001, Good to Great: Why Some Companies Make
          the Leap and Others Don’t)
        </p>
      </div>
      <Cta text="Make an enquiry" type="cta--accent" to="/contact" />
    </section>
  );
}

export default TheRightPeople;
