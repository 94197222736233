// ========== Imports ========== //

import "./CommentsTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import { CreateNews, DeleteNews, DeleteComment } from "../";
import { getComments, updateCommentStatus } from "../../../logic";

import { Pagination } from "../../Common";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaCommentStatusForm } from "../../../validators";
// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("CommentsTable");

// Receives the eventId and return the table

function SubCommentsTable() {
  // ========== Hook consts ========== //

  const [commentsData, setCommentsData] = useState("");

  const [currentPage, setCurrentPage] = useState();
  const [areComments, setAreComments] = useState(false);

  const params = useParams();

  let route = params.route;
  let mainCommentId = params.mainCommentId;

  const PageSize = 20;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return commentsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaCommentStatusForm) });

  const handleFormSubmit = (commentId, status) => {
    handleUpdateCommentStatus(commentId, status);
  };

  const handleUpdateCommentStatus = (commentId, status) => {
    try {
      updateCommentStatus(
        sessionStorage.token,
        commentId,
        status,

        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          toast.success("Comment status updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const reject = (status) => {
    return (
      <span style={{ color: "#ff0000" }}>
        <strong>{status}</strong> ❌
      </span>
    );
  };

  const pend = (status) => {
    return (
      <span style={{ color: "#8cb194" }}>
        <strong>{status}</strong> 🤔
      </span>
    );
  };

  const approve = (status) => {
    return (
      <span style={{ color: "#3a5540" }}>
        <strong>{status}</strong> 😁
      </span>
    );
  };

  const refreshChanges = () => {
    window.location.reload(false);
  };
  // ========== other consts ========== //

  function rowTable(comment, index) {
    // ========== JSX ========== //

    return (
      <tr key={`${Math.floor(Math.random() * 1000000000000)}${Date.now()}`}>
        <td>{comment.date.slice(0, 10)}</td>
        <td>{comment.name}</td>
        <td>{comment.email}</td>
        <td>{comment.comment.slice(0, 100)}</td>
        <td>
          {comment.status === "Approved" ? approve(comment.status) : null}
          {comment.status === "Pending" ? pend(comment.status) : null}
          {comment.status === "Rejected" ? reject(comment.status) : null}
          <form>
            <div>
              <label /* htmlFor="Approved" */>😁</label>
              <input
                {...register("status")}
                type="radio"
                // name="Approved"
                value="Approved"
                onChange={() => {
                  comment.status = "Approved";
                  handleFormSubmit(comment.id, "Approved");
                }}
              />
            </div>
            <div>
              <label /* htmlFor="Pending "*/>🤔</label>
              <input
                {...register("status")}
                type="radio"
                // name="Pending"
                value=" Pending"
                onChange={() => handleFormSubmit(comment.id, "Pending")}
              />
            </div>
            <div>
              <label /* htmlFor="Rejected" */>❌</label>
              <input
                {...register("status")}
                type="radio"
                // name="Rejected"
                value=" Rejected"
                onChange={() => handleFormSubmit(comment.id, "Rejected")}
              />
            </div>
          </form>
        </td>

        <td className="buttons-td">
          <DeleteComment commentId={comment.id} />
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getComments(route, mainCommentId, (error, commentsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setCommentsData(commentsArray.map(rowTable));
        if (commentsArray.length > 0) setAreComments(true);
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (commentsData !== "") {
    return (
      <div className="comments-table">
        <h1>Manage Comment Threads</h1>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Comment...</th>
              <th>
                <button
                  className="button button--white button--refresh"
                  onClick={refreshChanges}
                >
                  Refresh Changes
                </button>{" "}
                <br></br>
                Status <br></br>
                Approved - Pending - Rejected
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        {!areComments ? (
          <p className="no-comments p--medium">Currently no comments</p>
        ) : null}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={commentsData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default SubCommentsTable;
