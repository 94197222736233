// ========== Imports ========== //

import "./FormAnalysisTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../../Common";
import { getFormResponse } from "../../../../logic";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

function FormAnalysisTable({ environment }) {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState("");
  const [tableData, setTableData] = useState("");

  // ========== other consts ========== //

  // ========== useEffects ========== //

  useEffect(() => {
    getFormResponseData();
  }, []);

  const params = useParams();
  let route = params.route;

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              //   navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              //   navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setResponsesData(formDataRetrieved);
        }
      );
    } catch (error) {
      //   navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  useEffect(() => {
    setTableData(generateTableData(responsesData));
  }, [responsesData]);

  const generateTableData = (tableData) => {
    const analysedResults = [
      {
        sectionA: {
          sectionScore: "",
          possibleScore: "21",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
      {
        sectionB: {
          sectionScore: "",
          possibleScore: "",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
      {
        sectionC: {
          sectionScore: "",
          possibleScore: "",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
      {
        sectionD: {
          sectionScore: "",
          possibleScore: "",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
      {
        sectionE: {
          sectionScore: "",
          possibleScore: "",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
      {
        sectionF: {
          sectionScore: "",
          possibleScore: "",
          percentageScore: "",
          adjustWith: "",
          adjustedPercentageScore: "",
        },
      },
    ];
    return analysedResults;
  };

  // ========== JSX ========== //

  if (responsesData !== "") {
    return (
      <div className="analysis-table">
        <h2 className="h1">Response Analysis</h2>
        <h3 className="h2">Overall Scoring</h3>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>Section</th>
              <th>Section Score</th>
              <th>Possible score</th>
              <th>% Score</th>
              <th>Adjust with</th>
              <th>Adj % Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A - Purpose</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>B - Perseverance</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>C – Growth Mindset</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>D - Ownership</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>E – Supportive-Demanding (Personal development)</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>F - Supportive-Demanding (professional development)</td>
              <td>Section Score</td>
              <td>Possible score</td>
              <td>% Score</td>
              <td>Adjust with</td>
              <td>Adj % Score</td>
            </tr>
            <tr>
              <td>TOTAL SCORE:</td>
              <td></td>
              <td>Possible score</td>
              <td></td>
              <td></td>
              <td>Adj % Score</td>
            </tr>
            {tableData
              ? tableData.map((row) => {
                  return (
                    <tr key={row.section}>
                      <td>{row.section}</td>
                      <td>{row.sectionScore}</td>
                      <td>{row.possibleScore}</td>
                      <td>{row.percentageScore}</td>
                      <td>{row.adjustWith}</td>
                      <td>{row.adjustedPercentageScore}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
          {/* <tbody>{currentTableData ? currentTableData : null}</tbody> */}
        </table>
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default FormAnalysisTable;
