import { ServerError, ClientError } from "errors";
import { validateToken, validateCallback } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function retrieveUser(token, callback) {
  validateToken(token);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const json = xhr.responseText;

      const data = JSON.parse(json);
      const user = {
        name: data.name,
        email: data.username,
        favorites: data.favorites,
      };

      callback(null, user);
    }
  };

  xhr.open("GET", `${API_URL}/users`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send();
}

export default retrieveUser;
