import numbersBlock from "../../Assets/numbersBlock.png";
import { Cta } from "../Common";

import "./CharacterQualities.css";

function CharacterQualities() {
  return (
    <section className="section character-qualities">
      <div>
        <h2 className="h2">Character Qualities</h2>
        <p className="p--m p--left--mobile p--intro">
          &nbsp;
          <br />
          The CAST assessment is broken down into five sections, it has been
          designed to provide insights into the following capacities:
        </p>
      </div>

      <div className="numbers-image-container">
        <img src={numbersBlock} alt="" />
      </div>
      <ul className="qualities-list">
        <li className="p--xl p--bold p--centered p--secondary">
          Supportive - demanding style
        </li>
        <li className="p--xl p--bold p--centered p--color-three">
          Growth mindset
        </li>
        <li className="p--xl p--bold p--centered p--accent">Perseverance</li>
        <li className="p--xl p--bold p--centered p--color-four">Ownership</li>
        <li className="p--xl p--bold p--centered">Sense of purpose</li>
      </ul>
      <p className="p--m p--left--mobile p--block  p--intro">
        From these insights the following outcomes can be determined:
      </p>
      <ul className="outcomes-list">
        <li className="p--m p--left--mobile">
          Capacity for <b>handling challenging situations</b> and{" "}
          <b>overcoming obstacles</b>.
        </li>
        <div className="separating-line">&nbsp;</div>
        <li className="p--m p--left--mobile">
          <b>Development potential</b> in the short and long term.
        </li>
        <div className="separating-line">&nbsp;</div>
        <li className="p--m p--left--mobile">
          <b>People management</b> and <b>leadership</b> abilities.
        </li>
        <div className="separating-line">&nbsp;</div>
        <li className="p--m p--left--mobile">
          <b>Potential resilience</b> related to different{" "}
          <b>levels of responsibility</b>.
        </li>
        <div className="separating-line">&nbsp;</div>
        <li className="p--m p--left--mobile">
          <b>Developmental needs</b> to maximize progress.
        </li>
      </ul>

      <Cta text="Ask a question" type="cta--accent" to="/contact" />
    </section>
  );
}

export default CharacterQualities;
