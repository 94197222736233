import Joi from "joi";

const schemaSearchGeneralForm = Joi.object({
  query: Joi.string().trim().allow("").messages({
    "string.base": "please enter a valid query",
    "string.empty": "please enter a query",
  }),
  category: Joi.string().valid("All categories").messages({
    "string.base": "please select a valid category",
    "string.empty": "please select a category",
  }),
});

export default schemaSearchGeneralForm;
