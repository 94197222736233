const Joi = require("joi");

function validateRegisterCode(registerCode, explain = "register code") {
  const schema = Joi.string()
    .trim()
    .min(32)
    .max(32)
    .pattern(/^[a-zA-Z0-9]+$/)
    .required()
    .messages({
      "string.pattern.base":
        "register code must contain only alphanumerical characters",
      "string.base": "please enter a valid register code",
      "string.empty": "please enter a register code",
    });

  const result = schema.validate(registerCode);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else if (result.error.details[0].type === `string.empty`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else if (result.error.details[0].type === `string.pattern.base`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else throw new Error(`error with ${explain}: ${explain} is not valid`);
  }

  return `${explain} validated successfully`;
}

export default validateRegisterCode;
