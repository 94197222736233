import people_2 from "../../Assets/people_2.jpg";
import team_3 from "../../Assets/team_3.jpg";
import { Cta } from "../../Components/Common";
import { LuCheck, LuDot } from "react-icons/lu";

import "./WhatIsCastFor.css";

function WhatIsCastFor() {
  return (
    <section className="section what-is-cast-for">
      <div className="cast-purposes">
        <h2 className="h2">What is CAST for?</h2>
        <p className="p--m p--left--mobile">
          <b>Assist organizations</b> in achieving excellent outcomes in{" "}
          <b>staff selection, retention, and development.</b>
          <br />
          &nbsp;
        </p>
        <p className="p--m p--left--mobile">
          <b>Assist professionals</b> in{" "}
          <b>identifying their developmental needs</b> and objectives to{" "}
          <b>achieve their personal and professional ambitions</b>.
          <br />
          &nbsp;
        </p>
        <p className="p--m p--left--mobile">
          It can be used in <b>any profession</b>, at <b>any level</b> - from{" "}
          <b>entry to CEO</b>. It is especially relevant to those involved in{" "}
          <b>demanding and challenging work</b>.
          <br />
          &nbsp;
        </p>
        <p className="p--m p--left--mobile">
          For example, <b>executive leadership</b>, <b>senior management</b>,
          and <b>challenging professions</b> such as <b>police</b>,{" "}
          <b>elite sports</b>, and <b>medical/health/social services</b> among
          others.
        </p>
      </div>
      <div>
        <div className="people-image-container">
          <img
            src={people_2}
            alt="a group of 8 successful people from varied demographics"
          />
        </div>
        <div class="wrapper">
          <div class="speechbubble speechbubble--wide">
            <p className="p--sm p--centered">
              The CAST Assessment tool is brilliantly insightful and delivered
              in a careful, thoughtful, and affirming way that encourages
              self-care and self-challenge. It's different from other assessment
              tools in that it examines aspects of "character" (as opposed to
              personality), to understand individuals' potential for leadership,
              resilience, and undertaking high-level responsibility.
              <span className="username">
                Development Lead, Marketing Director, England.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid--1x2">
        <div>
          <h3 className="h2">Hiring & Selection Processes</h3>
          <p className="p--m">
            &nbsp;
            <br />
            CAST can be used as part of the selection process at all levels of
            an organization.
          </p>
          <ul>
            <li className="p--m">
              <LuCheck className="check-icon" />
              <span>Assessment Interview</span>
            </li>
          </ul>
          <p className="p--m">
            CAST is a character & values-based assessment that focuses on
            personal qualities that are relevant to professional performance.
            The assessment identifies,
          </p>
          <ul>
            <li className="p--m">
              <LuDot className="check-icon" />
              <span>
                where a person is in their current level of development.
              </span>
            </li>
            <li className="p--m">
              <LuDot className="check-icon" />
              <span>
                the capacity for taking on responsibility in potentially
                stressful & challenging roles.
              </span>
            </li>
            <li className="p--m">
              <LuDot className="check-icon" />
              <span>
                recommendations & approaches for personal & professional
                development.
              </span>
            </li>
          </ul>
          <p className="p--m">
            Therefore, as well as being helpful for selection, the feedback from
            CAST can be used for a development plan.
          </p>
        </div>
        <div>
          <h3 className="h2">Improve Recruitment & Retention</h3>
          <p className="p--m">
            &nbsp;
            <br />
            Recruiting the right people improves performance & staff retention.
            This helps start a positive cycle. It reduces the ongoing costs of
            recruitment & the disruption caused by staff turnover. The cost
            savings can be invested in more productive processes.
          </p>
          <div className="team-image-container">
            <img src={team_3} alt="" />
          </div>
        </div>
        <div>
          <h3 className="h2">Professional & Personal Development</h3>
          <p className="p--m">
            &nbsp;
            <br />
            CAST can be used to look at areas of strength & weaknesses. It
            improves self-awareness & identifies developmental needs & pathways.
            As well as for employment positions it can also be used in other
            areas, such as to assist students at college & university.
          </p>
        </div>
        <div>
          <h3 className="h2">Improving Team Effectiveness</h3>
          <p className="p--m">
            &nbsp;
            <br />
            CAST provides insights into individual character. It includes how
            the person works with and manages others. By assessing a whole team,
            you can also see how well-balanced the team is. A team development
            plan can be developed to strengthen the team.
          </p>
        </div>
      </div>

      <div className="paragraph--closing">
        <p className="p--m p--left--mobile">
          Whether it is personal/professional development or business-wide
          changes you are hoping to achieve, CAST can help you reach those
          goals.
        </p>
      </div>
      <Cta text="Ask a question" type="cta--accent" to="/contact" />
    </section>
  );
}

export default WhatIsCastFor;
