import { ServerError, ClientError } from "errors";
import {
  validateText,
  validateCallback,
  validateToken,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function createForm(token, fullName, route, callback) {
  validateToken(token);
  validateText(fullName, "full name");
  validateText(route, "route");
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;
    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("POST", `${API_URL}/forms`);

  xhr.setRequestHeader("Content-type", "application/json");
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send(`{ "fullName": "${fullName}", "route": "${route}"}`);
}

export default createForm;
