import { Cta } from "../Common";

import "./ServicesHeroBanner.css";

function ServicesHeroBanner() {
  return (
    <section className="section block--primary services-hero-banner">
      <h1 className="circles-hero-banner-title">
        CAST Service
        <br />
        Options & Prices
      </h1>
      <p className="p--m title-paragraph">
        Which option is the best fit for you? Here's some info to help you
        choose.
      </p>
      <Cta text="Contact us" type="cta--accent" to="/contact" />
    </section>
  );
}

export default ServicesHeroBanner;
