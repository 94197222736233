import "./WhatWeDo.css";

function WhatWeDo() {
  return (
    <section className="what-we-do">
      <div className="section background-texture-layer">
        <div className="center-container">
          <h3 className="h2 p--left--mobile">What We Do</h3>
          <p className="p--m">
            CAST assesses the fit of an individual to a specific role by
            assessing how that person sees the world, approaches problems, and
            relates to others. CAST is particularly successful in determining
            fit for roles that require a prominent level of resilience, drive,
            and determination to succeed.
            <br />
            &nbsp;
          </p>
          <h2 className="h2 p--left--mobile">We Are Unique</h2>
          <p className="p--m">
            We use our experience & personal approach to get a true insight into
            a person’s character & development potential. We evaluate their
            assessments carefully & create a personalized feedback report.
            <br />
            &nbsp;
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhatWeDo;
