import { useEffect } from "react";
import useAnalyticsPageViewSenderGA4 from "../utils/useAnalyticsPageViewSenderGA4";

import {
  WhatWeDo,
  PurposeHeroBanner,
  WhoWeAre,
  WhatIsCastFor,
} from "../Components/Purpose";

function Purpose() {
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Purpose");
  }, []);
  return (
    <section className="content">
      <PurposeHeroBanner />
      <WhatIsCastFor />
      <WhatWeDo />
      <WhoWeAre />
    </section>
  );
}

export default Purpose;
