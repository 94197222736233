import Joi from "joi";

const schemaContactForm = Joi.object({
  firstName: Joi.string().trim().min(2).required().messages({
    "string.empty": "first name is not allowed to be empty",
    "string.pattern.base": "please enter your first name",
  }),
  surname: Joi.string().trim().min(2).required().messages({
    "string.empty": "surname is not allowed to be empty",
    "string.pattern.base": "please enter your surname",
  }),
  email: Joi.string()
    .trim()
    .pattern(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
    .required()
    .max(254)
    .messages({
      "string.pattern.base": "please enter a valid email address",
      "string.base": "please enter a valid email address",
      "string.empty": "please enter an email ddress",
    }),
  /*  telephone: Joi.number().allow("").messages({
    "number.base": "please enter a valid telephone number and area code",
    "number.empty": "please enter a telephone number and area code",
  }), */
  /*  company: Joi.string().trim().allow("").messages({
    "string.base": "please enter a valid company name",
    "string.empty": "please enter an company name",
  }), */
  message: Joi.string().trim().min(5).max(2000).required().messages({
    "string.base": "please enter a valid message between 5 and 2000 characters",
    "string.empty": "please enter a message between 5 and 2000 characters",
  }),
  /* availability: Joi.string().trim().allow("").min(2).messages({
    "string.base": "please enter your availability",
    "string.empty": "please enter your availability",
  }), */
  subscribe: Joi.boolean(),
  onlineInPerson: Joi.boolean(),
  onlineFormBased: Joi.boolean(),
  workforceTeamEvaluation: Joi.boolean(),
  otherInquiry: Joi.boolean(),
});

export default schemaContactForm;
