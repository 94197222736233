// ========== imports ========== //

// ========== Page ========== //
function QuestionMcqList({
  question,
  questionText,
  register,
  qErrors,
  labels,
  onSubmit,
}) {
  // ========== Hook consts ========== //

  return (
    <div className="cast-form__question-container">
      <div className="cast-form__question cast-form__question--radio--column">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        <div className="radio-options">
          {labels.map((label, i) => {
            return (
              <li
                className="cast-form__question__qtext__listitem"
                key={
                  Date.now().toString() +
                  Math.floor(Math.random() * 1000000).toString()
                }
              >
                <input
                  type="radio"
                  id={question + "_" + i.toString()}
                  name={question}
                  value={i}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
                <label
                  htmlFor={question + "_" + i.toString()}
                  className="p--sm"
                >
                  {label}
                </label>
              </li>
            );
          })}
        </div>
        <div className="cast-form__error-message">
          {qErrors && (
            <p className="input__error-message">{qErrors?.message}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcqList;
