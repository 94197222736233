// ========== imports ========== //
import "./Contact.css";

import React, { useRef, useState, useEffect } from "react";

import useAnalyticsPageViewSenderGA4 from "../../utils/useAnalyticsPageViewSenderGA4";

import ContactHeroBanner from "./ContactHeroBanner";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaContactForm } from "../../validators";

import { ServerError } from "errors";
import { addSubscriber } from "../../logic";
// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function Contact() {
  // ========== Hook consts ========== //
  const [captchaToken, setCaptchaToken] = useState();
  const [emailBeingSent, setEmailBeingSent] = useState(false);

  const form = useRef();
  const captchaRef = useRef(null);

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Contact");
  }, []);

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
  };

  const handleAddSubscriber = (firstName, surname, email) => {
    try {
      addSubscriber(firstName, surname, email, (error) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else if (
            error.message.slice(0, 32) === "error 409: subscriber with email"
          ) {
            toast.success("Subscribed successfully 😀");
            logger.warn(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        reset();
        logger.debug("subscriber added");
        toast.success("Subscribed successfully 😀");
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const sendEmail = async () => {
    const token = captchaRef.current.getValue();

    if (!token) {
      toast.warn("ReCAPTCHA box must be validated");
      return;
    }

    const values = getValues();

    setEmailBeingSent(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/utilities/nodemailercontact`,
        values
      )
      .then((response) => {
        toast.success("Email sent successfully, we'll be in touch soon!");
        reset();
      })
      .catch((e) => {
        logger.error(e.message);
        toast.error("error: sorry, email could not be sent");
      });

    setEmailBeingSent(false);
    if (values.subscribe)
      handleAddSubscriber(values.firstName, values.surname, values.email);

    captchaRef.current.reset();
  };

  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(schemaContactForm),
    defaultValues: {
      onlineInPerson: false,
      onlineFormBased: false,
      workforceTeamEvaluation: false,
      otherInquiry: false,
    },
  });

  const logger = new Loggito("Contact");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div>
      <ContactHeroBanner />
      <div className="section contact">
        <div className="content">
          <form
            ref={form}
            onSubmit={handleSubmit((data) => sendEmail())}
            className="contact-form"
            noValidate={true}
          >
            <p className="p--m p--centered">
              Please get in touch by describing your enquiry below:
              <br />
              &nbsp;
            </p>
            <div className="grid grid--1x2 contact-form__grid">
              <div>
                <label className="p--m" htmlFor="firstName">
                  * First name:{" "}
                </label>
                <input
                  className="p--m"
                  type="text"
                  placeholder=""
                  name="firstName"
                  {...register("firstName", {})}
                />
                {errors.firstName && (
                  <p className="input__error-message">
                    {errors.firstName?.message}
                  </p>
                )}
              </div>
              <div>
                <label className="p--m" htmlFor="surname">
                  * Surname:{" "}
                </label>
                <input
                  className="p--m"
                  type="text"
                  placeholder=""
                  name="surname"
                  {...register("surname", {})}
                />
                {errors.surname && (
                  <p className="input__error-message">
                    {errors.surname?.message}
                  </p>
                )}
              </div>
              <div>
                <label className="p--m" htmlFor="email">
                  * Email address:{" "}
                </label>
                <input
                  className="p--m"
                  type="email"
                  placeholder=""
                  name="email"
                  {...register("email", {})}
                />
                {errors.email && (
                  <p className="input__error-message">
                    {errors.email?.message}
                  </p>
                )}
              </div>
            </div>

            <label className="p--m" htmlFor="message">
              * Message:
            </label>
            <textarea {...register("message", {})} className="p--m" />
            {errors.message && (
              <p className="input__error-message">{errors.message?.message}</p>
            )}

            <div className="serviceList">
              <p className="p--m">
                If you are interested in a particular service, please select
                from the list below:
              </p>
              <ul className="list p--m">
                <li>
                  <input
                    className="contact-form__checkbox"
                    type="checkbox"
                    placeholder=""
                    name="onlineInPerson"
                    {...register("onlineInPerson", {})}
                  />
                  <label
                    htmlFor="onlineInPerson"
                    className="contact-form__checkbox-label p--m"
                    type="checkbox"
                  >
                    Online in-person CAST Assessment
                  </label>
                  {errors.onlineInPerson && (
                    <p className="input__error-message">
                      {errors.onlineInPerson?.message}
                    </p>
                  )}
                </li>
                <li>
                  <input
                    className="contact-form__checkbox"
                    type="checkbox"
                    placeholder=""
                    name="onlineFormBased"
                    {...register("onlineFormBased", {})}
                  />
                  <label
                    htmlFor="onlineFormBased"
                    className="contact-form__checkbox-label p--m"
                    type="checkbox"
                  >
                    Online form-based CAST Assessment
                  </label>
                  {errors.onlineFormBased && (
                    <p className="input__error-message">
                      {errors.onlineFormBased?.message}
                    </p>
                  )}
                </li>
                <li>
                  <input
                    className="contact-form__checkbox"
                    type="checkbox"
                    placeholder=""
                    name="workforceTeamEvaluation"
                    {...register("workforceTeamEvaluation", {})}
                  />
                  <label
                    htmlFor="workforceTeamEvaluation"
                    className="contact-form__checkbox-label p--m"
                    type="checkbox"
                  >
                    Workforce/Team Evaluation Assessments
                  </label>
                  {errors.workforceTeamEvaluation && (
                    <p className="input__error-message">
                      {errors.workforceTeamEvaluation?.message}
                    </p>
                  )}
                </li>
                <li>
                  <input
                    className="contact-form__checkbox"
                    type="checkbox"
                    placeholder=""
                    name="otherInquiry"
                    {...register("otherInquiry", {})}
                  />
                  <label
                    htmlFor="otherInquiry"
                    className="contact-form__checkbox-label p--m"
                    type="checkbox"
                  >
                    Other Inquiry
                  </label>
                  {errors.otherInquiry && (
                    <p className="input__error-message">
                      {errors.otherInquiry?.message}
                    </p>
                  )}
                </li>
              </ul>
            </div>
            <div className="subscribe">
              <input
                className="contact-form__checkbox"
                type="checkbox"
                placeholder=""
                name="subscribe"
                {...register("subscribe", {})}
              />
              <label
                htmlFor="subscribe"
                className="contact-form__checkbox-label p--m"
                type="checkbox"
              >
                Subscribe to our newsletter to receive the latest updates:
              </label>
              {errors.subscribe && (
                <p className="input__error-message">
                  {errors.subscribe?.message}
                </p>
              )}
            </div>

            <div className="recaptcha-container">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </div>
            {emailBeingSent ? (
              <button type="button" className="cta cta--accent--clicked">
                Submit
              </button>
            ) : null}
            {!emailBeingSent ? (
              <button type="submit" className="cta cta--accent">
                Submit
              </button>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}

export default withContext(Contact);
