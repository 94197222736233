import Joi from "joi";

const schemaFileNameInput = Joi.object({
  fileName: Joi.string().trim().min(5).required().messages({
    "string.base": "please enter a valid file name",
    "string.empty": "please enter a file name",
  }),
});

export default schemaFileNameInput;
