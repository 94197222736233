import { Cta } from "../Common";

import "./InsightsHeroBanner.css";

function InsightsHeroBanner() {
  return (
    <section className="section block--primary insights-hero-banner">
      <div className="flipped-elements">
        <h1 className="circles-hero-banner-title">
          CAST <br />
          Insights
        </h1>
        <p className="p--m title-paragraph">
          What are the CAST insights and their outcomes?
        </p>
        <Cta text="Contact us" type="cta--accent" to="/contact" />
      </div>
    </section>
  );
}

export default InsightsHeroBanner;
