import "./PurposeHeroBanner.css";
import { Cta } from "../Common";

function PurposeHeroBanner() {
  return (
    <section className="section block--primary purpose-hero-banner">
      <div className="flipped-elements">
        <h1 className="circles-hero-banner-title">
          CAST <br />
          Purpose
        </h1>
        <p className="p--m title-paragraph">
          The uses of CAST and the research behind it.
        </p>
        <Cta text="Contact us" type="cta--accent" to="/contact" />
      </div>
    </section>
  );
}

export default PurposeHeroBanner;
