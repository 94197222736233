// ========== imports ========== //

// ========== Page ========== //
function QuestionShortText({
  question,
  questionText,
  register,
  qErrors,
  section,
}) {
  // ========== Hook consts ========== //

  return (
    <div className="cast-form__question-container">
      <div className="cast-form__question">
        <label className="p--m cast-form__question__qtext" htmlFor={question}>
          {typeof questionText === "string" && section !== "1" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {typeof questionText === "string" && section === "1" ? (
            <>
              <b>{questionText}</b>
            </>
          ) : null}
          {typeof questionText === "object"
            ? questionText.map((text, i) => {
                if (i === 0) {
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      <b>{question}</b>: {text}
                    </li>
                  );
                } else
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      {text}
                    </li>
                  );
              })
            : null}
        </label>
        <input
          className="p--m"
          type="text"
          placeholder=""
          name={question}
          id={question}
          {...register(question, {})}
        />
      </div>
      <div className="cast-form__error-message">
        {qErrors && <p className="input__error-message">{qErrors?.message}</p>}
      </div>
    </div>
  );
}

export default QuestionShortText;
