// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSectionE } from "../../../../validators";

import { QuestionAssessmentContainer, QuestionMcq03 } from "./FormComponents";

// ========== Page ========== //

function SectionE({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerE,
    handleSubmit: handleSubmitE,
    reset,
    formState: { errors },
    getValues: getValuesE,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionE),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "E" && value) {
            setValue(key, value.response, {});
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
          } */
        if (key[0] === "E") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => form.current.requestSubmit(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesE();

    console.log(values);

    const groupedValuesObject = {
      E1: {
        response: values.E1,
        score: values.E1_score,
        comment: values.E1_comment,
      },
      E2: {
        response: values.E2,
        score: values.E2_score,
        comment: values.E2_comment,
      },
      E3: {
        response: values.E3,
        score: values.E3_score,
        comment: values.E3_comment,
      },
      E4: {
        response: values.E4,
        score: values.E4_score,
        comment: values.E4_comment,
      },
      E5: {
        response: values.E5,
        score: values.E5_score,
        comment: values.E5_comment,
      },
      E6: {
        response: values.E6,
        score: values.E6_score,
        comment: values.E6_comment,
      },
      E7: {
        response: values.E7,
        score: values.E7_score,
        comment: values.E7_comment,
      },
      E8: {
        response: values.E8,
        score: values.E8_score,
        comment: values.E8_comment,
      },
      E9: {
        response: values.E9,
        score: values.E9_score,
        comment: values.E9_comment,
      },
      E10: {
        response: values.E10,
        score: values.E10_score,
        comment: values.E10_comment,
      },
      E11: {
        response: values.E11,
        score: values.E11_score,
        comment: values.E11_comment,
      },
      E12: {
        response: values.E12,
        score: values.E12_score,
        comment: values.E12_comment,
      },
      E13: {
        response: values.E13,
        score: values.E13_score,
        comment: values.E13_comment,
      },
      E14: {
        response: values.E14,
        score: values.E14_score,
        comment: values.E14_comment,
      },
      E15: {
        response: values.E15,
        score: values.E15_score,
        comment: values.E15_comment,
      },
      E16: {
        response: values.E16,
        score: values.E16_score,
        comment: values.E16_comment,
      },
    };

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        groupedValuesObject,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section E");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={handleSubmitE((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section E</h2>
        <p className="p--m">
          In this next section, you will be asked about parenting. This is not
          specifically about your own parents, but rather what you think is the
          most suitable way of parenting. In a general sense, parenting can also
          be thought of in terms of facilitating development, and therefore has
          something in common with leadership, management, and mentoring. You
          will be presented with a set of statements. Please think about how
          much each statement is likely to support or hinder development.
          <br />
          <br />
          Don't get too concerned about the issue of how old the subject/person
          may be. Focus on the essence of what is being stated.
          <br />
          <br />
          Score these statements from 0-3 according to how well they reflect
          qualities that are likely to support or hinder development.
          <br />
          <br />
          0 = may set back development <br />1 = unhelpful for development{" "}
          <br />2 = helpful for development <br />3 = very helpful for
          development.
          <br />
          &nbsp;
        </p>
      </div>
      {/*  {errors
        ? Object.entries(errors).map((error) => {
            return <li key={error}>{error}</li>;
          })
        : null} */}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E1"}
        questionText={"I can count on my parents if I have a problem."}
        register={registerE}
        qErrors={errors.E1}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"supportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E2"}
        questionText={
          "My parents expect me to follow family rules about daily life."
        }
        register={registerE}
        qErrors={errors.E2}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"demanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E3"}
        questionText={"My parents spend time just talking and being with me."}
        register={registerE}
        qErrors={errors.E3}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"supportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E4"}
        questionText={"My parents don't like me to tell them my troubles."}
        register={registerE}
        qErrors={errors.E4}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"unsupportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E5"}
        questionText={"My parents hardly ever praise me for doing well."}
        register={registerE}
        qErrors={errors.E5}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"unsupportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E6"}
        questionText={
          "My parents believe I have a right to my own point of view."
        }
        register={registerE}
        qErrors={errors.E6}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"supportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E7"}
        questionText={
          "My parents tell me that their ideas are correct and I shouldn’t question them."
        }
        register={registerE}
        qErrors={errors.E7}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E7"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"unsupportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E8"}
        questionText={"My parents often let me get away with things."}
        register={registerE}
        qErrors={errors.E8}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E8"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"undemanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E9"}
        questionText={
          "My parents are respectful and thoughtful towards my privacy."
        }
        register={registerE}
        qErrors={errors.E9}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E9"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"supportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E10"}
        questionText={
          "My parents make most of the decisions about what I can do."
        }
        register={registerE}
        qErrors={errors.E10}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E10"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"unsupportive"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E11"}
        questionText={"My parents point out ways I could do better."}
        register={registerE}
        qErrors={errors.E11}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E11"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"demanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E12"}
        questionText={
          "When I do something wrong, my parents don't have any consequences or talk with me about it."
        }
        register={registerE}
        qErrors={errors.E12}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E12"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"undemanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E13"}
        questionText={
          "My parents expect me to try my best even when it’s hard."
        }
        register={registerE}
        qErrors={errors.E13}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E13"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"demanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E14"}
        questionText={
          "I don’t have a curfew or need to tell my parents where I am going."
        }
        register={registerE}
        qErrors={errors.E14}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E14"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"undemanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E15"}
        questionText={"My parents expect me to do chores around the house."}
        register={registerE}
        qErrors={errors.E15}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E15"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"demanding"}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitE((data) => saveFormData())}
        question={"E16"}
        questionText={
          "My parents do not mind whether I hand in my homework on time."
        }
        register={registerE}
        qErrors={errors.E16}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitE((data) => saveFormData())}
          question={"E16"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerE}
          category={"undemanding"}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionE);
