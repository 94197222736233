// ========== Imports ========== //

import "./Settings.css";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { joiResolver } from "@hookform/resolvers/joi";
import withContext from "../../utils/withContext";
import { updateUserDetails } from "../../logic";
import { FormatError, ServerError } from "errors";
import {
  schemaSettingsNameForm,
  schemaSettingsPasswordForm,
} from "../../validators";
import Loggito from "../../utils/Loggito";

// ========== Component ========== //

function Settings({ onUpdateUserDetails }) {
  // ========== Hook consts ========== //

  const {
    register: registerName,
    handleSubmit: handleSubmitName,
    formState: { errors: errorsName },
  } = useForm({ resolver: joiResolver(schemaSettingsNameForm) });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
  } = useForm({ resolver: joiResolver(schemaSettingsPasswordForm) });

  // ========== consts ========== //

  const logger = new Loggito("Settings");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Function: updates details in db depending on form id ========== //

  const handleFormSubmit = (data, form) => {
    try {
      const formValues = {
        formId: null,
        updatedName: null,
        password: null,
        newEmail: null,
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      };

      formValues.formId = form;

      if (form === "nameForm") {
        formValues.updatedName = data.updatedName;
        formValues.password = data.password;
      }

      if (form === "passwordForm") {
        if (data.newPassword !== data.confirmNewPassword)
          throw new FormatError("New and confirmed passwords do not match");
        formValues.oldPassword = data.oldPassword;
        formValues.newPassword = data.newPassword;
        formValues.confirmNewPassword = data.confirmNewPassword;
      }

      if (form === "emailForm") {
        formValues.newEmail = data.newEmail;
        formValues.password = data.password;
      }

      try {
        updateUserDetails(
          sessionStorage.token,
          formValues,

          function (error) {
            if (error) {
              if (error instanceof ServerError) {
                toast.error(error.message);
                logger.error(error.message);
              } else {
                toast.warn(error.message);
                logger.warn(error.message);
                if (
                  error.message === "session timed out, please log in again"
                ) {
                  delete sessionStorage.token;
                  window.location.reload(false);
                }
              }
              return;
            }

            // form.reset();

            toast.success("User details updated successfully");

            //TODO:logout here
            // onUpdateUserDetails();
          }
        );
      } catch (error) {
        logger.warn(error.message);
        toast.warn(error.message);
      }
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  // ========== jsx ========== //

  return (
    <main className="settings">
      <div>
        <h2 className="h2">Change Password:</h2>
        <form
          id="passwordForm"
          action=""
          onSubmit={handleSubmitPassword((data) =>
            handleFormSubmit(data, "passwordForm")
          )}
        >
          <div>
            <div>
              <label htmlFor="password">old password</label>
              <input
                type="password"
                placeholder="type old password..."
                name="oldPassword"
                id="oldPassword"
                {...registerPassword("oldPassword", {})}
              />
              <p>{errorsPassword.oldPassword?.message}</p>
            </div>
            <div>
              <label htmlFor="newPassword">new password</label>
              <input
                type="password"
                placeholder="type new password..."
                name="newPassword"
                id="newPassword"
                {...registerPassword("newPassword", {})}
              />
              <p>{errorsPassword.newPassword?.message}</p>
            </div>
            <div>
              <label htmlFor="confirmNewPassword">confirm password</label>
              <input
                type="password"
                placeholder="retype new password..."
                name="confirmNewPassword"
                id="confirmNewPassword"
                {...registerPassword("confirmNewPassword", {})}
              />
              <p className="input__error-message">
                {errorsPassword.confirmNewPassword?.message}
              </p>
            </div>
          </div>
          <button type="submit" className="button button--white-inverse">
            Update
          </button>
        </form>
      </div>

      <div>
        <h2 className="h2">Change Admin Name:</h2>
        <form
          id="nameForm"
          action=""
          onSubmit={handleSubmitName((data) =>
            handleFormSubmit(data, "nameForm")
          )}
        >
          <div>
            <div>
              <label htmlFor="updatedName">new name</label>
              <input
                type="txt"
                placeholder="type new name..."
                name="updatedName"
                id="updatedName"
                {...registerName("updatedName", {})}
              />
              <p>{errorsName.updatedName?.message}</p>
            </div>
            <div>
              <label htmlFor="password">password</label>
              <input
                type="password"
                placeholder="type password..."
                name="password"
                id="passwordName"
                {...registerName("password", {})}
              />
              <p>{errorsName.password?.message}</p>
            </div>
          </div>
          <button type="submit" className="button button--white-inverse">
            Update
          </button>
        </form>
      </div>
    </main>
  );
}

export default withContext(Settings);
