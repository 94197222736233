import { Cta } from "../Common";

import "./AssessmentHeroBanner.css";

function AssessmentHeroBanner() {
  return (
    <section className="section block--primary assessment-hero-banner">
      <h1 className="circles-hero-banner-title">
        CAST <br />
        Assessment
      </h1>
      <p className="p--m title-paragraph">
        The Procedure, Analysis, Report, & Recommendations.
      </p>
      <Cta text="Contact us" type="cta--accent" to="/contact" />
    </section>
  );
}

export default AssessmentHeroBanner;
