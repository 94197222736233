import { useEffect } from "react";
import useAnalyticsPageViewSenderGA4 from "../utils/useAnalyticsPageViewSenderGA4";

import {
  CharacterQualtities,
  LevelsOfResponsibility,
  InsightsHeroBanner,
  TheGoodNews,
} from "../Components/CastInsights";

function HowCastWorks() {
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Insights");
  }, []);
  return (
    <section className="content">
      <InsightsHeroBanner />
      <CharacterQualtities />
      <LevelsOfResponsibility />
      <TheGoodNews />
    </section>
  );
}

export default HowCastWorks;
