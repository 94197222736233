const Joi = require("joi");

function validateResetPasswordCode(
  resetPasswordCode,
  explain = "reset password code"
) {
  const schema = Joi.string()
    .trim()
    .min(8)
    .max(8)
    .pattern(/^[a-zA-Z0-9]+$/)
    .required()
    .messages({
      "string.pattern.base":
        "reset password code is an 8-character alphanumerical code",
      "string.base": "please enter a valid code",
      "string.empty": "please enter a code",
    });

  const result = schema.validate(resetPasswordCode);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else if (result.error.details[0].type === `string.empty`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else if (result.error.details[0].type === `string.pattern.base`)
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    else throw new Error(`error with ${explain}: ${explain} is not valid`);
  }

  return `${explain} validated successfully`;
}

export default validateResetPasswordCode;
