import React, { useState, useEffect } from "react";
import "./MainComments.css";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { getCommentsClient } from "../../../logic";
import { AddMainComment, SubComments } from ".";

function MainComments({ route }) {
  const [commentsData, setCommentsData] = useState();
  const [replyIsActive, setReplyIsActive] = useState(false);
  const [activeReplyDate, setActiveReplyDate] = useState(false);

  const logger = new Loggito("Main Comments");

  useEffect(() => {
    loadComments();
  }, []);

  const loadComments = () => {
    try {
      return getCommentsClient(route, null, (error, commentsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setCommentsData(commentsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const handleReply = (id) => {
    setReplyIsActive(!replyIsActive);
    setActiveReplyDate(id);
  };

  return (
    <div className="main-comments-section">
      <ul className="list">
        {commentsData
          ? commentsData.map((comment) => {
              return (
                <li key={comment.id}>
                  <p className="name">{`${comment.name} says...`}</p>
                  <p className="comment p--m">{comment.comment}</p>
                  <p className="date">{comment.dateString}</p>
                  {replyIsActive && activeReplyDate === comment.id ? (
                    <button
                      className="cta cta--outline"
                      onClick={() => handleReply(comment.id)}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      className="cta cta--secondary"
                      onClick={() => handleReply(comment.id)}
                    >
                      Reply
                    </button>
                  )}
                  {replyIsActive && activeReplyDate === comment.id ? (
                    <AddMainComment
                      route={route}
                      type="sub"
                      mainCommentId={comment.id}
                    />
                  ) : null}
                  <SubComments route={route} mainCommentId={comment.id} />
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}

export default MainComments;
