import Joi from "joi";

const schemaResetPasswordForm = Joi.object({
  email: Joi.string()
    .trim()
    .pattern(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
    .required()
    .max(254)
    .messages({
      "string.pattern.base": "please enter a valid email address",
      "string.base": "please enter a valid email address",
      "string.empty": "please enter an email address",
    }),
  resetPasswordCode: Joi.string()
    .trim()
    .min(8)
    .max(8)
    .pattern(/^[a-zA-Z0-9]+$/)
    .required()
    .messages({
      "string.pattern.base":
        "reset passord code is an eight character alphanumerical code",
      "string.base": "please enter a valid code",
      "string.empty": "please enter a code",
    }),
  password: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please enter a password",
    }),
  confirmNewPassword: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please enter a password",
    }),
});

export default schemaResetPasswordForm;
