// ================== Imports ================== //

import "./Search.css";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Loggito from "../../../utils/Loggito";

import { schemaSearchGeneralForm } from "../../../validators";
// ================== Component ================== //

function Search({ onQuery }) {
  // ================== Consts ================== //
  const logger = new Loggito("Search");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaSearchGeneralForm) });
  // ================== Functions ================== //

  const handleSubmitForm = (data) => {
    const category = data.category;
    const query = data.query;
    onQuery(query, category);
  };

  logger.info("return");

  // ================== jsx ================== //

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
        <div className="category-input-container">
          <div className="category-input-select-container">
            <label className="p--m label--select" htmlFor="category">
              Choose a category:
            </label>

            <select
              className="p--m input--select"
              {...register("category")}
              name="category"
              // defaultValue={newsData.category}
              defaultValue={"All categories"}
            >
              <option value="All categories">All categories</option>
              {/* <option value="Category 1">Category 1</option>
              <option value="Category 2"> Category 2</option>
              <option value="Category 3"> Category 3</option>
              <option value="Category 4"> Category 4</option> */}
            </select>
          </div>
          {errors.category && (
            <p className="input__error-message">{errors.category?.message}</p>
          )}
        </div>
        <div className="search-input-container">
          <div className="p--break">
            <input
              className="p--m"
              type="string"
              placeholder="Search news..."
              name="query"
              defaultValue={""}
              {...register("query", {})}
            />
            {errors.query && (
              <p className="p--m input__error-message">
                {errors.query?.message}
              </p>
            )}
          </div>
        </div>
        <button className="cta cta--secondary">Search</button>
      </form>
    </div>
  );
}

export default Search;
