// ========== Imports ========== //

import "./AdminMainPage.css";
import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Loggito from "../utils/Loggito";
import withContext from "../utils/withContext";
import { toast } from "react-toastify";
import {
  LoginPage,
  RegisterPage,
  AdminPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from ".";
import { retrieveUser } from "../logic";
import { ServerError } from "errors";

// ========== Page ========== //

function AdminMainPage({ handleLogoutClick }) {
  // ========== Hook consts ========== //

  const [name, setName] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("HomePage");

  // ========== useEffects ========== //

  // ========== Function to retrieve the user from the db and set the name ========== //

  useEffect(() => {
    logger.info("componentDidMount");

    if (sessionStorage.token) {
      try {
        retrieveUser(sessionStorage.token, (error, user) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            handleLogoutClick();
            return;
          }

          setName(user.name);

          logger.debug("setName", user.name);
        });
      } catch (error) {
        logger.warn(error.message);
        toast.warn(error.message);
      }
    }
  }, []);

  // ========== Function to navigate between pages ========== //

  // I think the . here is incorrect
  const handleReturn = () => {
    navigate("./");
  };

  const handleNavigationToHome = () => {
    navigate("/admin");
    logger.debug("navigate to home");
  };

  const handleNavigationToRegister = () => {
    navigate("register");

    logger.debug("navigate to register");
  };

  const handleNavigateTo = (location) => {
    navigate(`/`);
    navigate(location);
  };

  // ========== Functions ========== //

  const handleUpdateUserDetails = () => {
    handleLogoutClick();
  };

  // ========== jsx ========== //

  return (
    <div className="">
      <main className="">
        <Routes>
          <Route
            path="/login"
            element={
              sessionStorage.token ? (
                <Navigate to="/admin/admin-home" />
              ) : (
                <LoginPage
                  handleNavigationToRegister={handleNavigationToRegister}
                  handleNavigationToHome={handleNavigationToHome}
                />
              )
            }
          />
          <Route
            path="/register"
            element={
              sessionStorage.token ? (
                <Navigate to="/admin/admin-home" />
              ) : (
                <RegisterPage />
              )
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/*"
            element={
              sessionStorage.token ? (
                <AdminPage handleLogoutClick={handleLogoutClick} name={name} />
              ) : (
                <Navigate to="/admin/login" />
              )
            }
          />
        </Routes>
      </main>
      <footer className=""></footer>
    </div>
  );
}

export default withContext(AdminMainPage);
