import { Cta } from "../Common";

import AssessmentProcedureDiagram from "../../Assets/assessmentProcedure.png";
import assessmentMainBanner from "../../Assets/assessment-main-banner.png";

import "./AssessmentProcedure.css";

function AssessmentProcedure(props) {
  return (
    <section className="section assessment-procedure-section">
      <div className="center-container grid grid--1x2">
        <div className="column-container">
          <div>
            <h3 className="h2">
              CAST Assesses
              <br />
              &nbsp;
            </h3>
            <ul>
              <li className="p--m">
                The personal character, values & mindsets that are linked with
                resilience, positive performance, & development.
              </li>
              <li className="p--m">
                A person's resilience & development needs, in highly challenging
                situations.
              </li>
              <li className="p--m">
                Resilience is influenced by the relational environment as well
                as the individual. Therefore, identifying development needs can
                improve resilience.
              </li>
              <li className="p--m">
                The level of demand & responsibility that an individual is
                currently capable of.
              </li>
              <li className="p--m">
                How well a person is suited to working on their own & in a team.
                Some people are most effective when working as part of a group &
                others can manage the more isolated type of roles. Identifying
                this is especially helpful when it comes to remote working.
                Remote working suits some people, but others will struggle with
                it.
              </li>
              <li className="p--m">
                Potential for growth & development in the short to long term.
              </li>
            </ul>
          </div>
          <Cta text="Ask a question" type="cta--accent" to="/contact" />
        </div>
        <div className="column-container">
          <div className="assessment-procedure-image-container">
            <img
              src={AssessmentProcedureDiagram}
              alt="two people having an informal interview"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="assessment-main-banner-container">
          <img
            src={assessmentMainBanner}
            alt="people doing online assessments"
          />
        </div>
      </div>
      <div class="wrapper">
        <div class="speechbubble speechbubble--wide">
          <p className="p--sm p--centered">
            From Owner/Director to entry-level, almost a fifth of our workforce
            was assessed to help us better understand their needs and how they
            fit into the company’s overall mission. The results could not be
            more positive. Through a simple question and answer process,
            completed over a 75-minute session, we gained insight into
            employees’ strengths and challenges, appropriate methods of support,
            and current and future role suitability.
            <span className="username">Operations Manager, UK</span>
          </p>
        </div>
        <div class="speechbubble speechbubble--wide">
          <p className="p--sm p--centered">
            I recommend the assessment for any manager who wants to focus on
            selecting the appropriate staff for positions and identifying ways
            of developing resilience.
            <span className="username">Team Leader, N. Ireland</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default AssessmentProcedure;
