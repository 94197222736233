import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validatePassword,
  validateEmail,
  validateResetPasswordCode,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function resetAdminPassword(
  email,
  resetPasswordCode,
  password,
  confirmNewPassword,
  callback
) {
  //====== validation ======//
  validateCallback(callback);

  if (password !== confirmNewPassword)
    throw new FormatError("Passwords do not match");
  validateEmail(email);
  validateResetPasswordCode(resetPasswordCode);
  validatePassword(password);
  validatePassword(confirmNewPassword);

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      /* xhr.onerror = function () {
        console.log("API CALL ERROR");
      }; */
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/users/resetpassword`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      email: email,
      resetPasswordCode: resetPasswordCode,
      password: password,
      confirmNewPassword: confirmNewPassword,
    })
  );
}

export default resetAdminPassword;
