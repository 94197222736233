import Joi from "joi";

const schemaFormSection1 = Joi.object({
  detailsFullName: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
  detailsCandidateEmail: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
  detailsOrganisation: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
  detailsRole: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsGender: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsAssessor: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
  detailsDateAssessed: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
});

export default schemaFormSection1;
