import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validateDate,
  validateEmail,
  validateText,
  validateToken,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateSubscriber(token, route, subscriberDataToUpdate, callback) {
  const { firstName, surname, email, dateSubscribed } = subscriberDataToUpdate;

  //====== validation ======//
  validateToken(token);
  validateText(firstName, "first name");
  validateText(surname, "surname");
  validateEmail(email);
  if (!!dateSubscribed) validateDate(dateSubscribed, "date subscribed");
  validateText(route, "route");
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      /* xhr.onerror = function () {
        console.log("API CALL ERROR");
      }; */
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/subscribers/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      firstName: firstName,
      surname: surname,
      email: email,
      dateSubscribed: dateSubscribed,
    })
  );
}

export default updateSubscriber;
