import { ServerError, ClientError } from "errors";
import {
  validateText,
  validateEmail,
  validatePassword,
  validateCallback,
  validateRegisterCode,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function registerUser(name, email, password, registerCode, callback) {
  validateText(name);
  validateEmail(email);
  validatePassword(password);
  validateCallback(callback);
  validateRegisterCode(registerCode);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("POST", `${API_URL}/users`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    `{ "name": "${name}", "email": "${email}", "password": "${password}", "registerCode": "${registerCode}"}`
  );
}

export default registerUser;
