import { Cta } from "../Common";
import { LuCheckSquare } from "react-icons/lu";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { BiSolidQuoteAltRight } from "react-icons/bi";

import "./Services.css";

function Services() {
  return (
    <section className="section services">
      <div className="block--intro">
        <h2 className="h2">Service Options and Prices</h2>
        <p className="p--m p--left--mobile">
          CAST can be carried out in different ways and each has its benefits.
          Below are the packages provided.
          <br />
          <br />
          If you still aren't sure what option best fits your needs, please Get
          in touch, or Ask a question.
        </p>
      </div>
      <Cta text="Get in touch" type="cta--accent" to="/contact" />

      <div className="services--mobile">
        <div className="services-container">
          <div className="options-container">
            <div className="option--1 p--white">
              <h3 className="h3 p--left--mobile">
                Online in-person CAST Assessment
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Assessment Interview</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Full personalized report with graphs, full explanation, and
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>30-minute feedback consultation</span>
                </li>
              </ul>
              <p className="p--m p--bold p--left--mobile">
                Prices available on enquiry
              </p>
              <Cta text="Get in touch" type="cta--primary cta--wide" />
            </div>
          </div>
        </div>

        <div className="services-container services-container--bottom">
          <div className="options-container">
            <div className="option--2  p--white">
              <p className="p--xl p--centered">COMING SOON* </p>
              <h3 className="h3 p--left--mobile">
                Online form-based CAST Assessment
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Online form-based assessment</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Personalized report with graphs, explanations, and
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    15-minute feedback consultation for each candidate
                  </span>
                </li>
              </ul>
              <p className="p--m p--bold p--left--mobile">
                Prices available on enquiry
              </p>
              <Cta text="Get in touch" type="cta--primary cta--wide" />
            </div>
          </div>
        </div>

        <div className="services-container services-container--bottom">
          <div className="options-container">
            <div className="option--3  p--white">
              <p className="p--xl p--centered">COMING SOON* </p>
              <h3 className="h3 p--left--mobile">
                Workforce/Team Evaluation Assessments
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Online form-based assessment</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Summary report on the overall findings for the group with
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Additional consultation can be arranged to explore the
                    results and implications for development
                  </span>
                </li>
              </ul>
              <p className="p--m p--bold p--left--mobile">
                Prices available on enquiry
              </p>

              <Cta text="Get in touch" type="cta--primary cta--wide" />
            </div>
          </div>
        </div>
      </div>

      <div className="services--desktop">
        <div className="services-container">
          <div className="options-container">
            <div className="option--1 p--white">
              <h3 className="h3 p--centered">
                Online in-person CAST Assessment
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Assessment Interview</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Full personalized report with graphs, full explanation, and
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>30-minute feedback consultation</span>
                </li>
              </ul>
              <div className="enquire-cta-container">
                <p className="p--m p--bold p--centered">
                  Prices available on enquiry
                </p>
                <Cta text="Get in touch" type="cta--primary cta--wide" />
              </div>
            </div>
            <div className="option--2  p--white">
              <p className="p--xl p--centered">COMING SOON* </p>
              <h3 className="h3 p--centered">
                Online form-based CAST Assessment
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Online form-based assessment</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Personalized report with graphs, explanations, and
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    15-minute feedback consultation for each candidate
                  </span>
                </li>
              </ul>
              <div className="enquire-cta-container">
                <p className="p--m p--bold p--centered">
                  Prices available on enquiry
                </p>
                <Cta text="Get in touch" type="cta--primary cta--wide" />
              </div>
            </div>
            <div className="option--3  p--white">
              <p className="p--xl p--centered">COMING SOON* </p>
              <h3 className="h3 p--centered">
                Workforce/Team Evaluation Assessments
              </h3>
              <ul>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>Online form-based assessment</span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Summary report on the overall findings for the group with
                    recommendations
                  </span>
                </li>
                <li className="p--m">
                  <LuCheckSquare className="check-icon" />
                  <span>
                    Additional consultation can be arranged to explore the
                    results and implications for development
                  </span>
                </li>
              </ul>
              <div className="enquire-cta-container">
                <p className="p--m p--bold p--centered">
                  Prices available on enquiry
                </p>
                <Cta text="Get in touch" type="cta--primary cta--wide" />
              </div>
            </div>
          </div>
          <div className="semicircles-container">
            <div className="semicircle--1 p--white"></div>
            <div className="semicircle--2 p--white"></div>
            <div className="semicircle--3 p--white"></div>
          </div>
        </div>
      </div>
      <div className="block">
        <h3 className="h3 p--left--mobile">
          1. Online in-person CAST Assessment
        </h3>

        <p className="p--m">
          This allows the greatest depth of understanding as the interviewer can
          explore and check responses to get a fuller picture.
        </p>
        <p className="p--m">
          This process is most suitable for the selection and development of
          management and senior professionals. It is also especially relevant to
          any professional who wants to focus in-depth on their development.
        </p>
        <div class="wrapper">
          <div class="speechbubble speechbubble--large speechbubble--wide">
            <p className="p--sm p--centered">
              From Owner/Director to entry-level, almost a fifth of our
              workforce was assessed to help us better understand their needs
              and how they fit into the company’s overall mission.
              <br />
              <br /> The results could not be more positive. Through a simple
              question and answer process, completed over a 75-minute session,
              we gained insight into employees’ strengths and challenges,
              appropriate methods of support, and current and future role
              suitability.
              <br />
              <span className="username">Operations Manager, UK</span>
            </p>
          </div>
        </div>
        <h2 className="h2">Coming Soon</h2>
        <p className="p--m">
          *Although these options are still in development, feel free to get in
          touch about bespoke services. Workforce evaluations are currently
          available although via online face-to-face interviews.
        </p>
        <h3 className="h3 p--left--mobile">
          2. Online form-based CAST Assessment
        </h3>
        <p className="p--m">
          This allows a thorough assessment with a summary of findings and
          recommendations. It is most relevant for assessing a group of
          candidates applying for a management role. It can also be used for
          assessing a small team to get a snapshot of the team’s potential and
          development needs.
        </p>
        <h3 className="h3 p--left--mobile">
          3. Workforce/Team Evaluation Assessments
        </h3>
        <p className="p--m">
          Bespoke packages can be created for organizations that wish to
          undertake an evaluation of their whole workforce or parts of it. For
          example, a team of 10 or more, or a larger group of 50.
        </p>
      </div>

      <Cta text="Ask a question" type="cta--accent" to="/contact" />
    </section>
  );
}

export default Services;
