import Joi from "joi";

const schemaSettingsNameForm = Joi.object({
  updatedName: Joi.string().min(2).max(50).required(),
  password: Joi.string()
    .trim()
    .min(8)
    .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base":
        "password must contain at least one lower case letter, an upper case letter and a number",
      "string.base": "please enter a valid password",
      "string.empty": "please enter a password",
    }),
});

export default schemaSettingsNameForm;
