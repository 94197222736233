import { useEffect } from "react";
import useAnalyticsPageViewSenderGA4 from "../utils/useAnalyticsPageViewSenderGA4";

import {
  WhyCast,
  LandingTitle,
  Endorsements,
  TheCastTool,
  TheRightPeople,
  WhyCharacter,
} from "../Components/Home";

function Home() {
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("Home");
  }, []);
  return (
    <section className="content">
      <LandingTitle />
      <TheCastTool />
      <WhyCharacter />
      <WhyCast />
      <TheRightPeople />
      <Endorsements />
    </section>
  );
}

export default Home;
