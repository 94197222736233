import React, { useRef, useState, useEffect } from "react";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Loggito from "../../../utils/Loggito";

import {
  schemaBlogHeroSubscribeForm,
  schemaBlogHeroSubscribeFormEmail,
} from "../../../validators";

import { ServerError } from "errors";
import { addSubscriber } from "../../../logic";

import "./BlogHero.css";

function BlogHero() {
  const form = useRef();
  const form2 = useRef();

  const [subscriberEmail, setSubscriberEmail] = useState(null);

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(schemaBlogHeroSubscribeFormEmail),
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
    getValues: getValues2,
  } = useForm({
    resolver: joiResolver(schemaBlogHeroSubscribeForm),
  });

  const logger = new Loggito("News Hero Block");

  // const handleOpenSubscriberPopUp = () => {}

  const handleAddSubscriber = () => {
    const values = getValues2();

    try {
      addSubscriber(values.firstName, values.surname, values.email, (error) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else if (
            error.message.slice(0, 32) === "error 409: subscriber with email"
          ) {
            toast.success("Subscribed successfully 😀");
            logger.warn(error.message);
            setSubscriberEmail(null);
            changeDivStatus();
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        reset();
        logger.debug("subscriber added");
        toast.success("Subscribed successfully 😀");
        setSubscriberEmail(null);
        changeDivStatus();
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  function changeDivStatus() {
    if (deleteDivStyle.display === "none") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        background: "black",
        opacity: "50%",
        zIndex: "3",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        position: "fixed",
        top: "40%",
        left: "45%",
        padding: "15px",
        width: "95%",
        maxWidth: "600px",
        height: "auto",
        borderRadius: "10px",
        zIndex: "3",
        // new styles
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  return (
    <div className="block--primary blog-hero">
      <h1 className="circles-hero-banner-title">
        CAST News <br /> & Resources
      </h1>
      <div className="form-container">
        <form
          key={1}
          ref={form}
          onSubmit={handleSubmit((data) => {
            setSubscriberEmail(data.email);
            changeDivStatus();
          })}
          className="contact-form"
          noValidate={true}
        >
          <p className="p--m p--centered p--subscribe">
            Subscribe to stay up to date with the latest news and resources.
          </p>
          <div className="input-container">
            <input
              className="p--m"
              type="email"
              placeholder="Enter email here..."
              name="email"
              {...register("email", {})}
            />
          </div>
          {errors.email && (
            <p className="input__error-message">{errors.email?.message}</p>
          )}
          {errors.firstName && (
            <p className="input__error-message">{errors.firstName?.message}</p>
          )}
          {errors.surname && (
            <p className="input__error-message">{errors.surname?.message}</p>
          )}

          {/* TODO: rewrite this code, first the email needs to be validated, if valid changes div status */}
          <button type="submit" className="cta cta--accent">
            Subscribe
          </button>
        </form>

        <div>
          <div style={pageBlockStyle} className="p--m"></div>
          <div style={deleteDivStyle} className="p--m">
            <div className="popup--subscribe">
              <p>Enter your details to subscribe</p>
              <form
                key={2}
                ref={form2}
                onSubmit={handleSubmit2((data) => handleAddSubscriber())}
                className="contact-form"
                noValidate={true}
                onClick={() => {}}
              >
                <div className="">
                  <div className="input-container">
                    <label className="p--m" htmlFor="email">
                      Email:{" "}
                    </label>
                    <input
                      className="p--m"
                      type="email"
                      placeholder="Enter email here..."
                      defaultValue={subscriberEmail ? subscriberEmail : ""}
                      name="email"
                      {...register2("email", {})}
                    />
                    {errors2.email && (
                      <p className="input__error-message">
                        {errors2.email?.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="p--m" htmlFor="firstName">
                      First name:{" "}
                    </label>
                    <input
                      className="p--m"
                      type="text"
                      placeholder=""
                      name="firstName"
                      {...register2("firstName", {})}
                    />
                    {errors2.firstName && (
                      <p className="input__error-message">
                        {errors2.firstName?.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="p--m" htmlFor="surname">
                      Surname:{" "}
                    </label>
                    <input
                      className="p--m"
                      type="text"
                      placeholder=""
                      name="surname"
                      {...register2("surname", {})}
                    />
                    {errors2.surname && (
                      <p className="input__error-message">
                        {errors2.surname?.message}
                      </p>
                    )}
                  </div>
                </div>

                <button type="submit" className="cta cta--accent">
                  Subscribe
                </button>
              </form>
              <button
                className="cta cta--primary cta--cancel"
                onClick={() => {
                  changeDivStatus();
                  setSubscriberEmail(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogHero;
