// ========== imports ========== //
import "./SiteMap.css";

import { useNavigate, Link } from "react-router-dom";
// ========== component ========== //
function SiteMap() {
  // ========== hook consts ========== //

  const navigate = useNavigate();
  // ========== other consts ========== //

  const routes = [
    { route: "/Home", component: "/Home", key: "/Home", title: "Home" },
    {
      route: "/Purpose",
      component: "/Purpose",
      key: "/Purpose",
      title: "Purpose",
    },
    {
      route: "/Insights",
      component: "/Insights",
      key: "/Insights",
      title: "Insights",
    },
    {
      route: "/Assessment",
      component: "/Assessment",
      key: "/Assessment",
      title: "Assessment",
    },
    {
      route: "/Prices",
      component: "/Prices",
      key: "/Prices",
      title: "Prices",
    },
    {
      route: "/News",
      component: "/News",
      key: "/News",
      title: "News",
    },
    {
      route: "/Contact",
      component: "/Contact",
      key: "/contact",
      title: "Contact",
    },
    {
      route: "/Privacy-Policy",
      component: "/PrivacyPolicy",
      key: "/PrivacyPolicy",
      title: "Privacy Policy",
    },
    {
      route: "/Cookies-Policy",
      component: "/CookiesPolicy",
      key: "/CookiesPolicy",
      title: "Cookies Policy",
    },
    {
      route: "/Unsubscribe",
      component: "/Unsubscribe",
      key: "/Unsubscribe",
      title: "Unsubscribe",
    },
  ];

  // ========== jsx ========== //
  return (
    <nav className="section site-map content">
      <h1 className="h1">SITE MAP</h1>
      <ul className="p--m">
        {routes.map((element) => {
          return (
            <li key={element.key}>
              <Link
                className="link"
                to={`/${element.route.slice(1).toLowerCase()}`}
              >
                {element.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default SiteMap;
