import levelsOfResponsibilityWhite from "../../Assets/levels-of-responsibility-pastel-white.png";

import "./LevelsOfResponsibility.css";

function LevelsOfResponsibility() {
  return (
    <section className="levels-of-responsibility">
      <div className="section background-texture-layer">
        <div className="block">
          <div className="paragraph--closing">
            <h2 className="h2">
              Levels of Responsibility & Development Potential
            </h2>
            <p className="p--m">
              CAST is used for assessing someone's suitability for managing
              different levels of responsibility. This can broadly be considered
              at the levels outlined in the diagram below.
            </p>
          </div>
          <div className="grid grid--1x2">
            <div className="mixed-elements-container">
              <p className="p--m">CAST helps to identify</p>
              <ul>
                <li className="p--m">
                  where someone presently is in their development
                </li>
                <li className="p--m">their suitability for different roles</li>
                <li className="p--m">their development style and potential</li>
                <li className="p--m">
                  their development needs and specific recommendations
                </li>
              </ul>
            </div>
            <div className="levels-of-responsibility-container centered-content">
              <img
                src={levelsOfResponsibilityWhite}
                alt="Levels of Responsibility Potential to Be Assessed"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LevelsOfResponsibility;
