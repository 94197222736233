import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

import NavbarHome from "./NavbarHome";

import { useLocation, Link } from "react-router-dom";

import { RWebShare } from "react-web-share";
import { FaShareSquare } from "react-icons/fa";

const APP_URL = process.env.REACT_APP_APP_URL;

function HeaderMobileTablet({ shareTitle, shareText }) {
  const location = useLocation();
  return (
    <header className="home-page__header mobile-tablet--block">
      {/* THIS IS OLD CODE AND DOESN'T DO ANYTHING */}
      {/*   <div className="desktop--block social-media-icons-container">
        <div>
          <a
            href="https://twitter.com/PaTassociates"
            title="Follow us on Twitter (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsTwitter className="nav__media-icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/patricktomlinsonassociates/"
            title="Follow us on LinkedIn (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsLinkedin className="nav__media-icon" />
          </a>
          <a
            href="https://www.instagram.com/patrick_tomlinson_associates/"
            title="Follow us on Instagram (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsInstagram className="nav__media-icon" />
          </a>
        </div>
      </div> */}
      <div className="web-share">
        <RWebShare
          data={{
            text: shareText,
            url: APP_URL + location.pathname,
            title: shareTitle,
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button className="p--m p--bold">
            <FaShareSquare />
          </button>
        </RWebShare>
      </div>
      <NavbarHome />
    </header>
  );
}

export default HeaderMobileTablet;
