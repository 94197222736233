const Joi = require("joi");

function validateToken(string, explain = "token") {
  const schema = Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({
      "string.base": `${explain} is not the correct type`,
      "string.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(string);

  if (result.error) {
    if (result.error.details[0].type === `string.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateToken;
