// ========== Imports ========== //

import "./Header.css";

import { useState, useEffect } from "react";
import { Menu } from "./";
import Loggito from "../../utils/Loggito";

import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";

// ========== Component ========== //

function Header({
  name,
  onLogoutClick,
  onSettingsClick,
  onHomeClick,
  view: viewHome,
}) {
  // ========== Hook consts ========== //

  const [view, setView] = useState(null); // [null, f () {}]
  const [menuView, setMenuView] = useState("home");

  // ========== Consts ========== //

  const logger = new Loggito("Header");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  const handleMenuClick = () => {
    setView("menu");

    logger.debug("setView", "menu");
  };

  const handleCloseClick = () => {
    setView(null);

    logger.debug("setView", null);
  };

  const handleLogoutClick = () => {
    onLogoutClick();
    handleCloseClick();
  };

  const handleHomeClick = () => {
    setMenuView("home");
    onHomeClick();
    handleCloseClick();
  };

  const handleSettingsClick = () => {
    setMenuView("settings");
    onSettingsClick();
    handleCloseClick();
  };

  // ========== jsx ========== //

  return (
    <header className=" header header__flex-container navigation-bar">
      <div className="navigation-bar header__flex-container">
        <div className="navigation-bar">
          {name ? (
            <p className="welcome">Hello, {name}!</p>
          ) : (
            <span>&nbsp;</span>
          )}

          {view === null ? (
            <RxHamburgerMenu
              className="icon nav__toggler icon--white"
              onClick={handleMenuClick}
            />
          ) : (
            <RxCross1
              className="icon nav__toggler icon--white"
              onClick={handleCloseClick}
            />
          )}
        </div>
        <h1 className="app-title">Admin Site</h1>
        {view === "menu" && (
          <Menu
            onLogoutClick={handleLogoutClick}
            onSettingsClick={handleSettingsClick}
            onHomeClick={handleHomeClick}
            onCloseClick={handleCloseClick}
            view={viewHome}
            menuView={menuView}
          />
        )}
      </div>
    </header>
  );
}

export default Header;
