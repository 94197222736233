import { Cta } from "../Common";

import "./TheCastTool.css";

function TheCastTool() {
  return (
    <section className="section the-cast-tool block--primary">
      <div className="title-block">
        <h2 className="h2">The CAST Tool</h2>
      </div>
      <div className="paragraph--closing">
        <p className="p--m ">
          Matching the right person to a specific role and investing in their
          development is crucial to achieving and maintaining employee
          engagement. In turn, this will have a vast impact on performance and
          positive outcomes, for individuals and organizations.
        </p>
        <p className="p--m">CAST can be used to help assess a person’s</p>
      </div>
      <div className="center-container grid grid--1x2">
        <div>
          <p className="h2 p--left--mobile">Roles</p>
          <p className="p--m">
            CAST is designed to find a new and innovative way of identifying
            suitability for different roles and development needs. Especially,
            leadership, senior executive, management, and challenging
            professional roles.
          </p>
        </div>
        <div>
          <p className="h2 p--left--mobile">Mindsets</p>
          <p className="p--m">
            The result of many years of research and professional experience
            into the personal qualities and mindsets that are most associated
            with successful performance and development.
          </p>
        </div>
        <div>
          <p className="h2 p--left--mobile">Performance</p>
          <p className="p--m">
            Effective performance is influenced by many factors, such as quality
            of leadership, support, and culture.
          </p>
        </div>
        <div>
          <p className="h2 p--left--mobile">Potential</p>
          <p className="p--m">
            Each person’s unique character, capacity, and developmental needs
            play a vital part.
          </p>
        </div>
      </div>
      <div className="paragraph--closing">
        <p className="p--m">
          We know that the world of employment is becoming increasingly complex.
          There are many reasons for this, including the rate of change in many
          industries, the widespread introduction of remote working, and the
          changing expectations of employees.
        </p>
        <p className="p--m">
          Gallup research (2023) in over 300 countries has found widespread
          employee disengagement. In the USA this is reported to be as high as
          50% of the workforce disengaged and only 16% actively engaged. A lack
          of engagement is a key factor in poor performance and burnout.
          Personal characteristics alongside professional skills are the
          critical combination that influences performance in today’s workplace.
        </p>
      </div>
      <Cta text="Ask a question" type="cta--accent" to="/contact" />
    </section>
  );
}

export default TheCastTool;
