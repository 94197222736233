const { FormatError } = require("errors");

const Joi = require("joi");

function validateCategory(category, explain = "category") {
  const schema = Joi.string().trim().valid("All categories").messages({
    "string.base": "please select a valid category",
    "string.empty": "please select a category",
  });

  const result = schema.validate(category);

  if (result.error) {
    throw new FormatError(
      `error with ${explain}: ${result.error.details[0].message}`
    );
  }

  return `${explain} validated successfully`;
}

export default validateCategory;
