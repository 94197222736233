import { useEffect } from "react";
import "./Cta.css";
import { Link } from "react-router-dom";

import useAnalyticsEventsTrackerGA4 from "../../utils/useAnalyticsEventsTrackerGA4";

function Cta({ text, type, to }) {
  const gaEventsTracker = useAnalyticsEventsTrackerGA4();
  useEffect(() => {}, []);
  return (
    <Link
      to={to}
      className={"cta " + type}
      onClick={() => {
        gaEventsTracker(window.location.pathname, "click_cta");
      }}
    >
      {text}
    </Link>
  );
}

export default Cta;
