// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSectionA } from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqYesNoMaybe,
  QuestionMcqYesNo,
  QuestionMcqList,
  QuestionShortText,
  QuestionTextBox,
} from "./FormComponents";

// ========== Page ========== //

function SectionA({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    reset,
    formState: { errors },
    getValues: getValuesA,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionA),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
        // TODO: investigate the benefit of these attributes
        // shouldValidate: true,
        // shouldDirty: true,
        if (key[0] === "A") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
          // setValue(key, value.response, {});
        }
      }
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => form.current.requestSubmit(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesA();

    // TODO: create a function that turns each question's response, comment and score into an object
    // const valueObjects = values.map(() => {});

    const groupedValuesObject = {
      A1a: {
        response: values.A1a,
        score: values.A1a_score,
        comment: values.A1a_comment,
      },
      A1b: {
        response: values.A1b,
        score: values.A1b_score,
        comment: values.A1b_comment,
      },
      A2: {
        response: values.A2,
        score: values.A2_score,
        comment: values.A2_comment,
      },
      A3: {
        response: values.A3,
        score: values.A3_score,
        comment: values.A3_comment,
      },
      A4: {
        response: values.A4,
        score: values.A4_score,
        comment: values.A4_comment,
      },
      A5: {
        response: values.A5,
        score: values.A5_score,
        comment: values.A5_comment,
      },
      A6: {
        response: values.A6,
        score: values.A6_score,
        comment: values.A6_comment,
      },
    };

    console.log(groupedValuesObject);

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        groupedValuesObject,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      /*  onSubmit={(data) => {
        debugger;
      }} */
      onSubmit={handleSubmitA((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section A</h2>
        <p className="p--m">
          In this section, you will be asked a series of questions about
          yourself.
          <br />
          &nbsp;
        </p>
      </div>

      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A1a"}
        questionText={"Which one of these statements most fits with you?"}
        register={registerA}
        qErrors={errors.A1a}
        labels={[
          "I view my work as just a necessity of life – it pays the bills",
          "My work is one of the most important things in my life",
          "I view my job mainly as a way of progressing to other jobs",
        ]}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A1a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionTextBox
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A1b"}
        questionText={"Please explain your reason."}
        register={registerA}
        qErrors={errors.A1b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A1b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionTextBox
        question={"A2"}
        questionText={"What motivates you in your work"}
        register={registerA}
        qErrors={errors.A2}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A2"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionTextBox
        question={"A3"}
        questionText={
          "Think of a role model or mentor in your life (friend, family, colleague, etc). What qualities do you admire in them? Explain 3-6 qualities you admire in them."
        }
        register={registerA}
        qErrors={errors.A3}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A3"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionTextBox
        question={"A4"}
        questionText={
          "What do you want to be doing in 10 years? Why? How are you going to achieve your goals?"
        }
        register={registerA}
        qErrors={errors.A4}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A4"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionShortText
        question={"A5"}
        questionText={
          "Write down 4-6 words to describe the core values that you live by."
        }
        register={registerA}
        qErrors={errors.A5}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A5"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}
      <QuestionTextBox
        question={"A6"}
        questionText={
          "What would you like to be your life legacy? What plans do you have to achieve this?"
        }
        register={registerA}
        qErrors={errors.A6}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A6"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerA}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionA);
