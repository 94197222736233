import { ServerError, ClientError } from "errors";
import {
  validateText,
  validateEmail,
  validateCallback,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function addSubscribers(firstName, surname, email, callback) {
  validateText(firstName);
  validateText(surname);
  validateEmail(email);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("POST", `${API_URL}/subscribers`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      firstName,
      surname,
      email,
    })
  );
}

export default addSubscribers;
