// ========== imports ========== //

// ========== Page ========== //
function QuestionMcqYesNo({
  question,
  questionText,
  register,
  qErrors,
  onSubmit,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */
    <div className="cast-form__question-container">
      <div className="cast-form__question cast-form__question--radio">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        <div className="radio-options">
          <div>
            <label htmlFor={question + "_Yes"} className="p--sm">
              Yes
            </label>
            <input
              type="radio"
              id={question + "_Yes"}
              name={question}
              value={"Yes"}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor={question + "_No"} className="p--sm">
              No
            </label>
            <input
              type="radio"
              id={question + "_No"}
              name={question}
              value={"No"}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
        </div>
      </div>
      {/* ERROR BLOCK - WITHIN Q CONTAINER */}
      <div className="cast-form__error-message">
        {qErrors && <p className="input__error-message">{qErrors?.message}</p>}
      </div>
    </div>
  );
}

export default QuestionMcqYesNo;
